import { Button, colors, Modal, Text } from 'shared-library';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { TerminalModalIcons } from './TerminalModalIcons';
import { terminalProcess } from '../PaymentOptions';

const ModalContents = styled.div`
  width: 100%;
  text-align: center;
`;

const ButtonDiv = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const TerminalButton = styled(Button)`
  width: 190px;
  height: 44px;
  padding: 0;
`;

const Heading = styled(Text)`
  font-size: 24px;
`;

const SpaceDiv = styled.div`
  width: 20px;
`;

export const TerminalModal = ({
  errorText,
  terminalStatus,
  handleTryAgain,
  closeTerminalModal,
  handleClickContinue,
  children,
}) => {
  const { text, continueButton, changePayment, tryAgain } = terminalStatus
    ? terminalProcess[terminalStatus]
    : {};

  const getText = () => {
    return errorText
      ? errorText
      : terminalStatus && terminalProcess[terminalStatus]?.text
      ? terminalProcess[terminalStatus].text
      : null;
  };

  const onClickContinue = () => {
    handleClickContinue ? handleClickContinue() : closeTerminalModal();
  };

  return (
    <Modal title="Process Payment" onClose={closeTerminalModal}>
      <ModalContents>
        <Heading weight={700}>{text}</Heading>
        {getText() && <Text color={colors.ash}>{getText()}</Text>}
        <TerminalModalIcons type={terminalStatus} />
        <ButtonDiv>
          {continueButton && (
            <TerminalButton onClick={onClickContinue} variant="primary" size="small">
              Continue
            </TerminalButton>
          )}
          {changePayment && (
            <TerminalButton variant="outline" onClick={closeTerminalModal}>
              Change Payment
            </TerminalButton>
          )}
          <SpaceDiv />
          {tryAgain && (
            <TerminalButton onClick={handleTryAgain} variant="primary" size="small">
              Try Again
            </TerminalButton>
          )}
          {children}
        </ButtonDiv>
      </ModalContents>
    </Modal>
  );
};

TerminalModal.propTypes = {
  errorText: PropTypes.string,
  terminalStatus: PropTypes.string,
  handleTryAgain: PropTypes.func.isRequired,
};

TerminalModal.defaultProps = {
  errorText: '',
  terminalStatus: '',
};
