/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { ToastProvider } from 'react-toast-notifications';

import { PortalRoot } from 'packages/portal-root';

import './fonts.css';

import { Route, Switch } from 'react-router';
import { BrowserRouter, useRouteMatch } from 'react-router-dom';

import {
  AlertContextProvider,
  StudyContextProvider,
  BookingContextProvider,
  PiiContextProvider,
} from 'packages/booking-contexts';
import { Theme, ThemeProvider, Heading, Modal, Text, Notification } from 'shared-library';
import InvoiceSummary from './InvoiceSummary';
import Promo from './Promo';
import Referral from './Referral';
import { PaymentOptions } from './PaymentOptions';
import { TransactionTable } from './TransactionTable';
import { PaymentContext, PaymentContextProvider } from './PaymentContext';
import InvoiceSection from './InvoiceSection';
import { TransactionReconciliation } from './TransactionReconciliation';

const StyledSection = styled.section`
  margin-bottom: 30px;
  width: 100%;
`;

const Payment = ({ onChange, shouldDisplayReconciliation }) => {
  const { studyId, user } = useContext(PaymentContext);
  const [alertModal, setAlertModal] = useState(null);

  const hasAdminRole = user.roles.includes('admin');
  const openAlertModal = useCallback((title, text) => setAlertModal({ title, text }), []);
  const clearAlertModal = useCallback(() => setAlertModal(null), []);

  return (
    <>
      <ThemeProvider themeName={Theme.theme1}>
        <InvoiceSummary />
        <StyledSection>
          <Heading size={7}>Credits and Discounts</Heading>
          <Promo openAlert={openAlertModal} onChange={onChange} />
          <Referral openAlert={openAlertModal} />
          {/* TODO: Implement Credit Transactions fully as detailed in https://jira.prenuvo.com/browse/PRENTECH-1131 */}
          <InvoiceSection />
        </StyledSection>
        <PaymentOptions openAlert={openAlertModal} onChange={onChange} />
        <TransactionTable openAlert={openAlertModal} onChange={onChange} />
        {alertModal && (
          <PortalRoot>
            <Modal onClose={clearAlertModal} title={alertModal.title}>
              {alertModal.text && <Text>{alertModal.text}</Text>}
            </Modal>
          </PortalRoot>
        )}
        {hasAdminRole && shouldDisplayReconciliation && (
          <TransactionReconciliation studyId={studyId} openAlert={openAlertModal} />
        )}
      </ThemeProvider>
    </>
  );
};

const PaymentWithoutBookingContext = ({ onChange, shouldDisplayReconciliation }) => (
  <PaymentContextProvider>
    <Payment onChange={onChange} shouldDisplayReconciliation={shouldDisplayReconciliation} />
  </PaymentContextProvider>
);

const PaymentWithContext = ({ onChange, shouldDisplayReconciliation }) => {
  const { path } = useRouteMatch();
  if (path === '/admin/frontdesk/study/:studyId' || path === '/admin/study/:studyId/payment') {
    return (
      <AlertContextProvider>
        <BookingContextProvider>
          <StudyContextProvider>
            <PiiContextProvider>
              <PaymentWithoutBookingContext onChange={onChange} shouldDisplayReconciliation={shouldDisplayReconciliation} />
            </PiiContextProvider>
          </StudyContextProvider>
        </BookingContextProvider>
      </AlertContextProvider>
    );
  }
  return <PaymentWithoutBookingContext />;
};

const PaymentWithToast = () => {
  return (
    <ToastProvider autoDismiss components={{ Toast: Notification }} placement="bottom-right">
      <PaymentWithContext />
    </ToastProvider>
  );
};

const PaymentWithRouter = ({ onChange, shouldDisplayReconciliation = false }) => (
  <BrowserRouter>
    <Switch>
      <Route path="/admin/booking/study/:studyId" component={PaymentWithToast} />
      <Route path="/admin/booking" component={PaymentWithToast} />
      <Route
        path="/admin/frontdesk/study/:studyId"
        render={() => <PaymentWithContext onChange={onChange} shouldDisplayReconciliation={shouldDisplayReconciliation} />}
      />
      <Route path="/admin/study/:studyId/payment" component={PaymentWithToast} />
    </Switch>
  </BrowserRouter>
);

export default PaymentWithRouter;
