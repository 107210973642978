import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Payment } from 'packages/payment';

import { buildStudySelector } from '../../store/studies/selectors';
import { updateStatusSuccessAction } from '../../store/studies/actions';

const InvoicesAndPayments = () => {
  const { studyId } = useParams();
  const selectStudy = buildStudySelector(studyId);
  const study = useSelector(selectStudy);
  const dispatch = useDispatch();
  const onChange = (paidStatus, paidTime) => {
    if (paidStatus !== study.status.paid) {
      dispatch(updateStatusSuccessAction(studyId, { ...study.status, paid: paidStatus, paidTime }));
    }
  };

  return <Payment onChange={onChange} shouldDisplayReconciliation={true} />;
};

export default InvoicesAndPayments;
