import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Heading from '../Heading';
import Icon from '../Icon';
import Text from '../Text';
import colors from '../../colors';
import { fadeAndSlideInFromRight, fadeAndSlideOutToRight } from '../../animations';
import mergeClassNames from '../../mergeClassNames';

const Box = styled.div`
  align-items: top;
  background-color: ${colors.white};
  border: 1px solid ${colors.smoke};
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  position: relative;
  margin-bottom: 16px;
  width: 396px;

  &::after {
    content: '';
    background-color: ${({ color }) => color};
    bottom: -1px;
    display: block;
    height: 4px;
    left: -1px;
    position: absolute;
    right: -1px
  }

  &.entering {
    animation: ${fadeAndSlideInFromRight} 220ms forwards ease-out;
  }
  
  &.exiting {
    animation: ${fadeAndSlideOutToRight} 220ms forwards ease-in;
  }
`;

const Title = styled(Heading).attrs({
  size: 7,
})`
  margin-bottom: 2px;
`;

const Body = styled(Text)`
  margin-bottom: 0;
`;

const TextContainer = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const Notification = ({ children, className, appearance, transitionState }) => (
  <Box className={mergeClassNames([className, transitionState])} color={colors[appearance] || colors.ash}>
    <Icon type={appearance} />
    <TextContainer>
      {children}
    </TextContainer>
  </Box>
);

Notification.propTypes = {
  appearance: PropTypes.oneOf(['success', 'warning', 'error']),
  children: PropTypes.node.isRequired,
  transitionState: PropTypes.oneOf(['entering', 'entered', 'exiting', 'exited']).isRequired,
};

Notification.defaultProps = {
  appearance: 'success',
};

Notification.Title = Title;
Notification.Body = Body;
Notification.create = (title, body) => (
  <>
    <Title>{title}</Title>
    <Body>{body}</Body>
  </>
);

export default Notification;
