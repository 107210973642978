import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors';

const RadioContext = createContext(undefined);

const InputContainer = styled.div`
  align-items: center;
  display: inline-flex;
  margin: 9px 32px 9px 0;
  position: relative;
`;

const Input = styled.input.attrs({
  type: 'radio',
})`
  -webkit-appearance: none;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Label = styled.label`
  color: ${colors.charcoal};
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding-left: 25px;

  &::before,
  &::after {
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
  }

  &::before {
    background-color: ${colors.white};
    border: 1px solid ${colors.smoke};
    height: 20px;
    left: 0;
    top: 0;
    width: 20px;
  }

  &::after {
    height: 12px;
    left: 4px;
    top: 4px;
    width: 12px;
  }

  ${Input}:checked + &::before {
    border: 1px solid ${colors.primary};
  }

  ${Input}:checked + &::after {
    background-color: ${colors.primary};
  }

  ${Input}:not(:disabled) + &:hover::before,
  ${Input}:focus + &::before {
    border: 1px solid ${colors.primaryHover};
    box-shadow: 0 0 3px ${colors.primaryHover};
  }

  ${Input}:disabled + & {
    color: ${colors.smoke};
    cursor: default;
  }

  ${Input}:disabled:checked + &::before {
    border-color: ${colors.primaryDisabled};
  }

  ${Input}:disabled:checked + &::after {
    background-color: ${colors.primaryDisabled};
  }
`;

const RadioInput = ({ className, disabled, label, value }) => {
  const { handleRadioChange, name, selected } = useContext(RadioContext);
  return (
    <InputContainer className={className}>
      <Input
        checked={selected === value}
        disabled={disabled}
        id={value}
        name={name}
        onChange={() => handleRadioChange(value)}
        value={value}
      />
      <Label htmlFor={value}>{label || value}</Label>
    </InputContainer>
  );
};

RadioInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
};

RadioInput.defaultProps = {
  className: null,
  disabled: false,
  label: null,
};

const RadioGroup = ({ children, initialValue, name, onChange }) => {
  const [selected, setSelected] = useState(initialValue);
  useEffect(() => {
    if (selected !== initialValue) {
      setSelected(initialValue);
    }
  }, [initialValue]);
  const handleRadioChange = (value) => {
    if (value === selected) {
      return;
    }
    setSelected(value);
    onChange(value);
  };
  return (
    <RadioContext.Provider value={{ name, selected, handleRadioChange }}>
      {children}
    </RadioContext.Provider>
  );
};
RadioGroup.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
RadioGroup.defaultProps = {
  onChange: () => {},
};

RadioGroup.Input = RadioInput;

export default RadioGroup;
