import React, { useMemo, useState } from 'react';

import { getHpdBackendUrl } from 'packages/locations';
import { getApolloClient, LoadingSpinner, practitionerQueries, Text } from 'shared-library';
import { ApolloProvider, useQuery } from '@apollo/client';
import { Column, Label } from '../Layout';

const InvoicePayerTransactionDetailWithApollo: React.FC = ({ transaction }) => {
  const [apiEndpoint] = useState(getHpdBackendUrl());
  const apolloClient = getApolloClient(apiEndpoint);
  return useMemo(
    () => (
      <>
        {apiEndpoint && (
          <ApolloProvider client={apolloClient}>
            <Column>
              <Label>Invoice Payer</Label>
              <InvoicePayerTransactionDetail transaction={transaction} />
            </Column>
          </ApolloProvider>
        )}
      </>
    ),
    [transaction],
  );
};

const InvoicePayerTransactionDetail = ({ transaction }) => {
  const { loading, error, data } = useQuery(practitionerQueries.PRACTITIONERS_DETAILS, {
    variables: {
      id: transaction.details.invoicePayer,
    },
  });

  if (loading || error) {
    return <LoadingSpinner size="small" />;
  }

  if (!data || error) {
    return <Text>Error loading the invoice payer&apos;s name</Text>;
  }

  return (
    <Text>
      {data?.practitioner?.firstName} {data?.practitioner?.lastName}
    </Text>
  );
};

export default InvoicePayerTransactionDetailWithApollo;
