import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadingLogoFile from '../../images/loading-logo.gif';

const Overlay = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Image = styled.img`
  width: 81px;
`;

const LoadingLogo = ({ className }) => (
  <Overlay className={className}>
    <Image src={LoadingLogoFile} alt="" />
  </Overlay>
);

LoadingLogo.propTypes = {
  className: PropTypes.string,
};

LoadingLogo.defaultProps = {
  className: null,
};

export default LoadingLogo;
