import styled from 'styled-components';
import Text from '../Text';
import colors from '../../colors';

const Label = styled(Text).attrs({
  as: 'label',
  size: 'small',
  weight: '600',
})`
  display: block;
  margin: 0 0 6px;
`;

export const AshLabel = styled(Text).attrs({
  as: 'label',
  size: 'small',
  weight: '600',
  color: colors.ash,
})`
  display: block;
  margin: 0 0 6px;
`;

export default Label;
