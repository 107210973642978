import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import colors from '../../colors';

const calculateRadius = (size) => (size / 2) * 0.85;
const calculateStrokeWidth = (size) => size * 0.15;
const calculateDashValue = (radius, percentage) => {
  const circumference = 2 * 3.1415927 * radius;
  return circumference * percentage;
};

const rotateAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

const circleDashAnimation = (radius) => keyframes`
  0%, 25% {
    stroke-dashoffset: ${calculateDashValue(radius, 0.97)};
    transform: rotate(0);
  }

  50%, 75% {
    stroke-dashoffset: ${calculateDashValue(radius, 0.25)};
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: ${calculateDashValue(radius, 0.97)};
    transform: rotate(360deg);
  }
`;

const sizes = {
  small: 20,
  medium: 32,
  large: 48,
};

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  background-color: rgba(255, 255, 255, 0.7);

  align-items: center;
  display: flex;
  justify-content: center;

  svg {
    height: 48px;
    width: 48px;
  }
`;

const LoadingSpinnerSvg = styled.svg.attrs(({ size }) => ({
  viewBox: `0 0 ${sizes[size]} ${sizes[size]}`,
  xlmns: 'http://www.w3.org/2000/svg',
}))`
  animation: 2s linear infinite ${rotateAnimation};
  vertical-align: text-bottom;
  width: ${({ size }) => sizes[size]}px;

  circle {
    animation: 1.5s ease-in-out infinite both ${({ radius }) => circleDashAnimation(radius)};
    fill: transparent;
    stroke: ${({ color }) => color};
    stroke-dasharray: ${({ radius }) => calculateDashValue(radius, 1)};
    stroke-linecap: round;
    stroke-width: ${({ strokeWidth }) => strokeWidth}px;
    transform-origin: 50% 50%;
  }
`;

const LoadingSpinner = ({ className, color, fillArea, size }) => {
  const sizeVal = sizes[size];
  const radius = calculateRadius(sizeVal);
  const strokeWidth = calculateStrokeWidth(sizeVal);
  const spinner = (
    <LoadingSpinnerSvg
      className={className}
      color={color}
      radius={radius}
      size={size}
      strokeWidth={strokeWidth}
    >
      <circle cx={sizeVal / 2} cy={sizeVal / 2} r={radius} />
    </LoadingSpinnerSvg>
  );

  if (fillArea) {
    return <SpinnerContainer>{spinner}</SpinnerContainer>;
  }

  return spinner;
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  fillArea: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

LoadingSpinner.defaultProps = {
  className: null,
  color: colors.smoke,
  fillArea: false,
  size: 'small',
};

export default LoadingSpinner;
