import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from '../Card';
import Section from '../Section';
import { fadeAndSlideInFromTop } from '../../animations';

const Background = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 16px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
`;

const ModalCard = styled(Card)`
  animation: ${fadeAndSlideInFromTop} 0.2s ease-out forwards;
  width: 760px;
`;

const Modal = ({ children, className, onClose, title }) => {
  const handleBackgroundClick = useCallback((clickEvent) => {
    if (clickEvent.currentTarget !== clickEvent.target) {
      return;
    }
    onClose();
  }, []);

  return (
    <Background onClick={handleBackgroundClick}>
      <ModalCard className={className}>
        <Section title={title} onCloseClick={onClose}>
          {children}
        </Section>
      </ModalCard>
    </Background>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

Modal.defaultProps = {
  className: null,
  onClose: null,
  title: null,
};

export default Modal;
