import React, { ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';

import { colors, Card, Icon, Text } from 'shared-library';

export const Row = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -8px;
  margin-right: -8px;
`;

interface ColumnProps {
  align?: string;
  grow?: boolean;
  width?: string;
  right?: boolean;
}

export const Column = styled.div<ColumnProps>`
  ${({ align }) => align && `align-self: ${align};`}
  flex: ${({ grow }) => (grow ? '1' : '0')} 0 ${({ width }) => width || '33.33333%'};
  padding: 0 8px;
  margin-bottom: 16px;
  text-align: ${({ right }) => (right ? 'right' : 'left')};

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const Label = styled(Text).attrs({
  size: 'small',
  color: colors.ash,
  weight: '600',
})`
  display: block;
  margin-bottom: 1px;
  text-transform: none;
  margin-bottom: 0;
`;

interface LineItemContainerProps {
  heavy?: boolean;
  onClick?: () => void;
  spaced?: boolean;
}

const LineItemContainer = styled.div<LineItemContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ spaced }) => (spaced ? '8px' : '0')};
  font-weight: ${({ heavy }) => (heavy ? '700' : '600')};
  ${({ onClick }) => onClick && 'cursor: pointer;'}
`;

const LineItemLabel = styled(Text)`
  margin-right: auto;
  margin-bottom: 0;
`;

const LineItemAmount = styled(Text)`
  margin-bottom: 0;
`;

const ExpandIcon = styled(Icon)`
  margin-left: 5px;
  cursor: pointer;
`;

interface LineItemProps {
  amount: number | string;
  children?: ReactElement;
  heavy?: boolean;
  label: string;
  spaced?: boolean;
  tiny?: boolean;
  'data-testid'?: string;
}

export const LineItem: React.FC<LineItemProps> = ({
  amount,
  children,
  heavy,
  label,
  spaced,
  tiny,
  ...rest
}: LineItemProps) => {
  const [isExpanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => {
    if (children) {
      setExpanded(!isExpanded);
    }
  }, [isExpanded]);

  const { 'data-testid': dataTestId } = rest;

  return (
    <>
      <div data-testid={dataTestId}>
        <LineItemContainer
          heavy={heavy}
          onClick={children && toggleExpanded}
          spaced={spaced || !!children}
        >
          <LineItemLabel weight={heavy ? '700' : '500'}>
            {tiny ? <Text size="xsmall" color='#aaa'>{label}</Text> : label}
            {children && <ExpandIcon type={isExpanded ? 'minus' : 'add'} color={colors.primary} />}
          </LineItemLabel>
          <LineItemAmount weight={heavy ? '700' : '500'} align="right">
            {amount}
          </LineItemAmount>
        </LineItemContainer>
        {children && isExpanded && <Card>{children}</Card>}
      </div>
    </>
  );
};

LineItem.defaultProps = {
  children: null,
  heavy: false,
  spaced: false,
};
