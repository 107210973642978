import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { formatCurrency } from 'packages/formatters';
import { colors, Button, LoadingSpinner, TextInput } from 'shared-library';

import { StudyContext } from 'packages/booking-contexts';
import { fetchAppsResource } from 'packages/apis';
import { Column, LineItem, Row } from './Layout';
import { PaymentContext } from './PaymentContext';

const ApplyButton = styled(Button)`
  margin-bottom: 0;
`;

const Referral = ({ openAlert }) => {
  const { invoiceSummary, load, studyId } = useContext(PaymentContext);
  const { selectedLocation } = useContext(StudyContext);
  const currency = selectedLocation?.currency?.currency;
  const { discountReferral } = invoiceSummary;
  const [referralCode, setReferralCode] = useState('');
  const [isApplyingReferral, setApplyingReferral] = useState(false);
  const formattedDiscount = formatCurrency(Math.abs(discountReferral), currency);
  const applyReferralString = discountReferral === '0' ? 'Not Valid' : 'Apply Credit';

  const handleApplyReferral = async (e) => {
    e.preventDefault();
    if (!referralCode) {
      openAlert('Invalid Referral Code');
    }
    if (!studyId) {
      openAlert(
        'Referral Code Error',
        'Please choose a location and time of scan before processing a payment',
      );
      return;
    }
    setApplyingReferral(true);
    try {
      const { parsedBody: data } = await fetchAppsResource(
        `/payment/study/${studyId}/referral/${referralCode}`,
        'POST',
      );
      if (!data.referralStatus) {
        openAlert(
          'Referral Code Error',
          data?.errorMessage ||
            "Cannot add referral code since it is not the patient's first scan.",
        );
      } else if (data.transaction) {
        if (data.transaction.amount === 0) {
          openAlert(
            'Referral code added, but a discount will not be applied since it is not a WB scan',
          );
        }
        await load();
      }
      setReferralCode('');
      setApplyingReferral(false);
    } catch ({ response: error }) {
      const { data } = error;
      if (data.errorMessage) {
        openAlert('Referral Code Error', data?.errorMessage || '');
      }
      setApplyingReferral(false);
    }
  };
  return (
    <LineItem amount={formattedDiscount} label="Referral Code">
      <form onSubmit={handleApplyReferral}>
        <Row>
          <Column grow width="auto">
            <TextInput
              disabled={isApplyingReferral}
              label="Referral Code"
              onChange={(value) => setReferralCode(value)}
              placeholder="i.e. f61po"
              value={referralCode}
            />
          </Column>
          <Column align="flex-end" width="auto">
            <ApplyButton
              variant="primary"
              size="xsmall"
              disabled={isApplyingReferral || discountReferral === '0'}
              type="submit"
            >
              {isApplyingReferral ? (
                <LoadingSpinner color={colors.white} size="small" />
              ) : (
                applyReferralString
              )}
            </ApplyButton>
          </Column>
        </Row>
      </form>
    </LineItem>
  );
};

export default Referral;
