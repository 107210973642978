import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  colors,
  Button,
  LoadingSpinner,
  SelectInput,
  TextInput,
  CurrencyInput,
  Text,
} from 'shared-library';

import { BookingContext, StudyContext } from 'packages/booking-contexts';
import { fetchAppsResource } from 'packages/apis';
import { getEnvironment } from 'packages/locations';
import { Column, LineItem, Row } from './Layout';
import { PaymentContext } from './PaymentContext';

const ApplyButton = styled(Button)`
  margin-bottom: 0;
`;

const promoCategories = {
  all: 'All',
  employee: 'Employee',
  enterprise: 'Enterprise',
  influencer: 'Influencer',
  marketing: 'Marketing',
  physician: 'Physician',
  partnership: 'Partnership',
  other: 'Other',
};

const Promo = ({ openAlert, onChange }) => {
  const [selectedPromoCategory, setSelectedPromoCategory] = useState('all');
  const [promoOptions, setPromoOptions] = useState([]);
  const [promoToApply, setPromoToApply] = useState('');
  const [promoReason, setPromoReason] = useState('');
  const [promoAmount, setPromoAmount] = useState(0);
  const [isApplyingDiscount, setApplyingDiscount] = useState(false);

  const {
    selectedLocation,
    studyId,
    skuID,
    machineID,
    appointmentTime,
    isUpdateAppointment,
  } = useContext(StudyContext);
  const { promocodes, currentUser, isTemporaryHold } = useContext(BookingContext);
  const { load } = useContext(PaymentContext);

  const promos = selectedLocation?.catalog?.primary?.find(
    (catalogItem) => catalogItem.skuID === skuID,
  )?.promos;
  const noAppointmentTimeSelected = !machineID || !appointmentTime;
  const disablePromoButton = isUpdateAppointment || noAppointmentTimeSelected;

  const promoCategoryOptions = Object.entries(promoCategories).map(([value, label]) => {
    return { value, label };
  });

  const getDiscountAmount = (discount) => {
    if (discount.type === 'fixed') {
      return `- $${discount.amount} off`;
    }

    if (discount.type === 'percentage') {
      return `- ${discount.amount * 100}% off`;
    }

    return '';
  };

  useEffect(() => {
    if (promoToApply) {
      const deducedPromoCategory = promoOptions.find((promoOption) => promoOption.value === promoToApply).category;
      if (selectedPromoCategory !== deducedPromoCategory) {
        setPromoToApply('');
      }
    }
  }, [selectedLocation, skuID, appointmentTime, machineID, selectedPromoCategory]);

  useEffect(() => {
    if (promocodes?.length && promos?.length && currentUser?.user_id) {
      const allowedDiscountOptions = promocodes
        .filter((discount) => {
          const {
            booking_valid_from: bookingValidFrom,
            booking_valid_to: bookingValidTo,
          } = discount;

          if (!promos.includes(discount.promoID)) {
            return false;
          }

          const currentTime = Math.floor(Date.now() / 1000);
          if (discount.validFrom > currentTime || discount.validTo < currentTime) {
            return false;
          }

          // Hide discounts that are not valid for the selected appointment date
          const hideBookingValidDiscounts =
            bookingValidFrom &&
            bookingValidTo &&
            (bookingValidFrom > appointmentTime || bookingValidTo < appointmentTime);

          if (hideBookingValidDiscounts) {
            return false;
          }

          return true;
        })
        .filter(
          (discount) =>
            selectedPromoCategory === 'all' || selectedPromoCategory === discount.category,
        )
        .map((discount) => ({
          ...discount,
          value: discount.promoID,
          label: `${discount.name} ${getDiscountAmount(discount)} ${
            discount.notes && `(${discount.notes})`
          }`,
        }));
      const openDiscounts = allowedDiscountOptions.filter((discount) => discount.type === 'open');

      // TODO: eventually be done by permission - currently Eric, Kinglsey, Eloise, Elina, Dave
      const usersAllowedOpenDiscount = [
        'auth0|617f1a7225cb410071b6ca2b',
        'auth0|615b907d24402a0069fdc58e',
        'auth0|61f62714ee339a0068a4f51a',
        'auth0|5be21a83adc308198397773a',
        'auth0|5d530cfdcd90020ee3b8e0e8',
      ];

      if (openDiscounts?.length) {
        let reOrderedDiscountOptions = allowedDiscountOptions.filter(
          (discount) => discount.type !== 'open',
        );
        const environment = getEnvironment();
        const shouldIncludeOpenDiscounts =
          environment === 'PRODUCTION' && usersAllowedOpenDiscount.includes(currentUser.user_id);

        if (shouldIncludeOpenDiscounts || environment !== 'PRODUCTION') {
          reOrderedDiscountOptions = [...reOrderedDiscountOptions, ...openDiscounts];
        }
        setPromoOptions(reOrderedDiscountOptions);
      } else {
        setPromoOptions(allowedDiscountOptions);
      }
    }
  }, [promocodes, promos, currentUser, selectedPromoCategory, appointmentTime]);

  const handleApplyDiscount = async (e) => {
    e.preventDefault();
    if (!studyId) {
      openAlert(
        'Discount Code Error',
        'Please choose a location and time of scan and add patient information before processing a payment',
      );
      return;
    }
    setApplyingDiscount(true);
    try {
      const { parsedBody } = await fetchAppsResource(
        `/payment/study/${studyId}/promo/${promoToApply}`,
        'POST',
        {
          promoReason,
          promoAmount,
          promoCategory: selectedPromoCategory,
        },
      );
      onChange && onChange(parsedBody?.paidStatus, parsedBody?.paidTime);

      if (parsedBody?.transaction) {
        await load();
        const remainBalance = parsedBody?.balance + parsedBody?.transaction.amount;
        if (remainBalance <= 0 && isTemporaryHold) {
          await fetchAppsResource('/calendar/convert-temporary-hold', 'POST', { studyId });
        }
      } else {
        openAlert('Discount Code Error', parsedBody);
      }
    } catch ({ response }) {
      openAlert('Discount Code Error', response?.data?.errorMessage);
    }
    setApplyingDiscount(false);
    setPromoToApply('');
    setSelectedPromoCategory('');
  };

  const getDiscountType = (promoId) => {
    const matchDiscount = promocodes?.find((discount) => discount.promoID === promoId);

    if (matchDiscount) {
      return matchDiscount.type;
    }

    return null;
  };

  if (noAppointmentTimeSelected) {
    return (
      <div>
        <Text>Promo / Discount</Text>
        <Text>
          Please select the appointment location, scan type, and date & time before applying
          discount.
        </Text>
      </div>
    );
  }

  return (
    <>
      <LineItem amount="" label="Promo / Discount">
        <form onSubmit={handleApplyDiscount}>
          <Row>
            <Column grow width="auto">
              <SelectInput
                label="Category"
                options={promoCategoryOptions}
                value={promoCategoryOptions.find(
                  (option) => option.value === selectedPromoCategory,
                )}
                onChange={({ value }) => {
                  setSelectedPromoCategory(value);
                }}
              />
            </Column>
            <Column grow width="auto">
              <SelectInput
                label="Discount Code"
                options={promoOptions}
                value={
                  promoToApply ? promoOptions.find((option) => option.value === promoToApply) : null
                }
                onChange={({ promoID, category }) => {
                  setPromoToApply(promoID);
                  setSelectedPromoCategory(category); // to update the category selection retroactively
                }}
                disabled={!selectedPromoCategory}
                placeholder={
                  !selectedPromoCategory ? 'Please select a category' : 'Select Discount'
                }
              />
            </Column>
            {getDiscountType(promoToApply) === 'open' && (
              <Column grow width="auto">
                <CurrencyInput
                  label="Discount Amount"
                  value={promoAmount === 0 ? '' : promoAmount}
                  displayType="input"
                  fixedDecimalScale
                  decimalScale={2}
                  allowNegative={false}
                  placeholder="0.00"
                  required
                  onChange={({ floatValue }) => setPromoAmount(floatValue)}
                />
              </Column>
            )}
          </Row>
          <Row>
            <Column grow width="auto">
              <TextInput
                label="Reason"
                onChange={setPromoReason}
                required
                placeholder="Enter the reason for applying discount..."
              />
            </Column>
            <Column align="flex-end" width="auto">
              <ApplyButton
                disabled={isApplyingDiscount || disablePromoButton}
                type="submit"
                variant="primary"
                size="small"
              >
                {isApplyingDiscount ? (
                  <LoadingSpinner color={colors.white} size="small" />
                ) : (
                  'Apply Discount'
                )}
              </ApplyButton>
            </Column>
          </Row>
          {disablePromoButton && (
            <Text>
              Please select the appointment location, scan type, and date & time before applying
              discount.
            </Text>
          )}
        </form>
      </LineItem>
    </>
  );
};

Promo.propTypes = {
  openAlert: PropTypes.func.isRequired,
};

export default Promo;
