import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PortalRoot } from 'packages/portal-root';
import { StudyContext } from 'packages/booking-contexts';
import { loadStripeTerminal } from '@stripe/terminal-js';

import { formatCurrency, titleCase } from 'packages/formatters';
import { getEnvironment } from 'packages/locations';
import {
  colors,
  Button,
  CheckboxInput,
  CurrencyInput,
  LoadingSpinner,
  Text,
  TextInput,
} from 'shared-library';
import { TerminalModal } from '../TerminalModal';

import { Column, Label, Row } from '../Layout';
import { transactionShape } from '../propTypes';
import { PaymentContext } from '../PaymentContext';
import { fetchAppsResource } from '../../apis';
import { discoverReaders, fetchConnectionToken } from '../PaymentOptions';

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const TransactionRefund = ({ onClose, transaction, openAlert, onChange }) => {
  const [isRefunding, setRefunding] = useState(false);
  const [refundDetails, setRefundDetails] = useState({
    amount: transaction.transactionType === 'reconciliation' ||
      ['promocode', 'referral code'].includes(transaction.paymentType)
      ? transaction.amount
      : 0,
    reason: '',
    externalRefund: false,
  });
  const { selectedLocation } = useContext(StudyContext);
  const { load, refund } = useContext(PaymentContext);

  const [showTerminalModal, setShowTerminalModal] = useState(false);
  const [terminalStatus, setTerminalStatus] = useState('handOff');

  const [errorText, setErrorText] = useState('');
  const [stripeLocationId, setStripeLocationId] = useState(null);

  const eraseSpaces = (string) => {
    if (!string || typeof string !== 'string') return false;
    return string.split(/\s/).join('');
  };

  const handleConnectionError = (newErrorText = 'Error connecting via stripe connection token') => {
    setTerminalStatus('unsuccessful');
    if (typeof newErrorText !== 'string') {
      setErrorText('An unexpected error occurred');
    } else {
      setErrorText(newErrorText);
    }
  };

  const onFetchConnectionToken = () => {
    return fetchConnectionToken(
      selectedLocation.locationID,
      setStripeLocationId,
      handleConnectionError,
    );
  };

  const interacRefund = async () => {
    setShowTerminalModal(true);

    const StripeTerminal = await loadStripeTerminal();
    const terminal = StripeTerminal.create({
      onFetchConnectionToken,
      onUnexpectedReaderDisconnect: () => {
        handleConnectionError('Error connecting via stripe connection token');
      },
    });
    const config = {
      location: stripeLocationId,
      simulated: getEnvironment() !== 'PRODUCTION',
    };

    const connectionError = await discoverReaders(
      config,
      terminal,
      handleConnectionError,
      terminalStatus,
      setTerminalStatus,
    );

    if (connectionError) {
      return handleConnectionError(connectionError);
    }

    const { parsedBody: chargeId } = await fetchAppsResource(
      `/payment/terminal/interac/${transaction.details.referenceID}`,
    );
    const refundPaymentMethod = await terminal.collectRefundPaymentMethod(
      chargeId,
      refundDetails.amount * 100,
      'cad',
    );
    if (refundPaymentMethod.error) {
      const { error } = refundPaymentMethod;
      const { message } = error;
      handleConnectionError(`Failed to process refund: ${message}`);
    } else {
      const refundResult = await terminal.processRefund();
      if (refundResult.error) {
        const { error } = refundResult;
        const { message } = error;
        handleConnectionError(`Failed to process refund: ${message}`);
      } else {
        return refundResult;
      }
    }
  };

  const processRefund = async () => {
    let refundOrRemove;
    if (transaction.transactionType === 'reconciliation') {
      refundOrRemove = 'remove reconciliation';
    } else if (['promocode', 'referral code'].includes(transaction.paymentType)) {
      refundOrRemove = 'remove discount';
    } else {
      refundOrRemove = 'refund';
    }

    if (isNaN(refundDetails.amount) && !refundDetails.reason) {
      const removalAction = refundDetails.reason.replace('remove', 'removing');
      openAlert(
        `${titleCase(refundOrRemove)} Error`,
        `Please enter amount and reason for ${removalAction}.`,
      );
      return;
    }

    if (isNaN(refundDetails.amount)) {
      openAlert(`${titleCase(refundOrRemove)} Error`, `Please enter amount to ${refundOrRemove}.`);
      return;
    }

    if (!refundDetails.reason) {
      const removalAction = refundDetails.reason.replace('remove', 'removing');
      openAlert(
        `${titleCase(refundOrRemove)} Error`,
        `Please enter reason for ${removalAction}.`,
      );
      return;
    }

    setRefunding(true);
    const interacTransaction =
      transaction.details?.cardType &&
      transaction.details.cardType.includes('interac:') &&
      !refundDetails.externalRefund;
    if (interacTransaction) {
      const interactRefundStatus = await interacRefund();
      if (!interactRefundStatus) {
        return;
      }
    }

    const successCallback = async (data) => {
      if (typeof data.paidStatus !== 'undefined' && onChange) {
        onChange(data.paidStatus, data?.paidTime);
      }
      await load();
      setRefunding(false);
      onClose();
    };

    const failCallback = ({ response: err }) => {
      setRefunding(false);
      const { data } = err;
      openAlert('Refund Error', data.message ? data.message : data);
    };

    await refund(
      transaction.transactionID,
      {
        amount: refundDetails.amount,
        memo: refundDetails.reason,
        externalRefund: refundDetails.externalRefund,
        interacTransaction,
      },
      successCallback,
      failCallback,
    );
  };

  useEffect(() => {
    if (eraseSpaces(transaction.paymentType) === 'giftcertificateintegrated') {
      setRefundDetails({ ...refundDetails, amount: transaction.amount });
    }
  }, []);

  let transactionDescription;
  if (transaction.transactionType === 'reconciliation') {
    transactionDescription = (
      <Column>
        <Label>Reconciliation name</Label>
        <Text>{transaction?.paymentType}</Text>
        <Label>Reconciliation amount</Label>
        <Text>{formatCurrency(transaction.amount, transaction.currency)}</Text>
      </Column>
    )
  } else if (['referral code', 'promocode'].includes(transaction.paymentType)) {
    transactionDescription = (
      <Column>
        <Label>Discount name</Label>
        <Text>{transaction?.details?.referenceID || transaction?.details?.referralCode}</Text>
        <Label>Discount amount</Label>
        <Text>{formatCurrency(transaction.amount, transaction.currency)}</Text>
      </Column>
    );
  } else {
    transactionDescription = (
      <>
        <Column width="100%">
          <Label>
            {eraseSpaces(transaction.paymentType) !== 'giftcertificateintegrated'
              ? 'Max Refundable Amount'
              : 'Refundable Amount'}
          </Label>
          <Text>
            {formatCurrency(transaction.refundable || transaction.amount, transaction.currency)}
          </Text>
        </Column>
        <Column width="100%">
          {eraseSpaces(transaction.paymentType) !== 'giftcertificateintegrated' && (
            <CurrencyInput
              disabled={isRefunding}
              label="Amount"
              required
              id="refundAmount"
              value={refundDetails.amount === 0 ? '' : refundDetails.amount}
              suffix={` ${transaction.currency}`}
              placeholder="$0.00"
              displayType="input"
              fixedDecimalScale
              decimalScale={2}
              allowNegative={false}
              onChange={(values) => {
                if (values.value < transaction.refundable) {
                  setRefundDetails({ ...refundDetails, amount: values.value });
                } else {
                  setRefundDetails({
                    ...refundDetails,
                    amount: transaction.refundable,
                  });
                }
              }}
            />
          )}
        </Column>
      </>
    );
  }

  return (
    <>
      {showTerminalModal && (
        <PortalRoot>
          <TerminalModal
            errorText={errorText}
            terminalStatus={terminalStatus}
            handleTryAgain={interacRefund}
            closeTerminalModal={() => setShowTerminalModal(false)}
          />
        </PortalRoot>
      )}
      {(transaction.transactionType === 'charge' ||
        ['stripe', 'external credit', 'finance', 'external terminal', 'credit'].includes(
          transaction?.paymentType,
        )) && (
        <Row>
          <Column width="100%">
            <CheckboxInput
              disabled={isRefunding}
              id="external-refund"
              label="Mark as refunded externally"
              checked={refundDetails.externalRefund}
              onChange={() =>
                setRefundDetails({
                  ...refundDetails,
                  externalRefund: !refundDetails.externalRefund,
                })
              }
            />
          </Column>
          {transaction.details?.cardType && (
            <Column width="100%">
              <Label>Credit Card Type</Label>
              <Text>{transaction.details.cardType}</Text>
            </Column>
          )}
          {transaction.details?.cardLastFour && (
            <Column width="100%">
              <Label>Last 4 Digits</Label>
              <Text>{transaction.details.cardLastFour}</Text>
            </Column>
          )}
        </Row>
      )}
      {['externalLender', 'clinic', 'prepaid', 'eTransfer'].includes(transaction?.paymentType) && (
        <Row>
          <Column width="100%">
            <Label>Method</Label>
            <Text>{transaction.details ? transaction.details.paymentMethod : ''}</Text>
          </Column>
        </Row>
      )}
      {transaction.paymentType === 'invoice' && (
        <Row>
          <Column width="100%">
            <Label>Provider</Label>
            <Text>{transaction.details ? transaction.details.invoicePayer : ''}</Text>
          </Column>
        </Row>
      )}
      {transaction.paymentType === 'cheque' && (
        <Row>
          <Column width="100%">
            <Label>Cheque Number</Label>
            <Text>{transaction.details ? transaction.details.referenceID : ''}</Text>
          </Column>
        </Row>
      )}
      {transaction.paymentType === 'giftCertificate' && (
        <Row>
          <Column width="100%">
            <Label>Certificate Number</Label>
            <Text>{transaction.details ? transaction.details.referenceID : ''}</Text>
          </Column>
        </Row>
      )}
      <Row>
        {transactionDescription}
        <Column width="100%">
          <TextInput
            disabled={isRefunding}
            as="textarea"
            id="refundReason"
            label="Reason"
            placeholder="Enter a reason for the refund..."
            onChange={(value) => setRefundDetails({ ...refundDetails, reason: value })}
            required
            rows={3}
            value={refundDetails.reason}
          />
        </Column>
      </Row>
      <ButtonContainer>
          <Button
            disabled={isRefunding}
            variant={'primary'}
            onClick={processRefund}
            size="small"
          >
            {isRefunding ? <LoadingSpinner color={colors.white} size="small" /> : 'Process'}
          </Button>
          <Button onClick={onClose} size="small" variant="secondary">
            Cancel
          </Button>
      </ButtonContainer>
    </>
  );
};

TransactionRefund.propTypes = {
  onClose: PropTypes.func.isRequired,
  openAlert: PropTypes.func.isRequired,
  transaction: transactionShape.isRequired,
};

export default TransactionRefund;
