import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from '../../colors';
import Heading from '../Heading';
import Icon from '../Icon';
import LoadingSpinner from '../LoadingSpinner';
import Text from '../Text';

const Header = styled.div`
  border-bottom: 1px solid ${colors.primary};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  position: relative;
`;

const StyledHeading = styled(Heading).attrs({
  size: 6,
})`
  line-height: 26px;
  margin-bottom: 4px;
  ${({label}) => label && `
    margin-left: 50px;
  `}
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-left: 12px;
  vertical-align: bottom-text;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 20px;
`;

const Container = styled.section`
  margin-bottom: 30px;
  width: 100%;
  ${({disabled}) => disabled && `
    opacity: 0.3;
  `}
`;

const MessageWrapper = styled.div`
  margin: 60px auto;
  max-width: 450px;
  text-align: center;
`;

const StyledLabel = styled.div`
  width: 34px;
  height: 34px;
  padding-top: 6px;
  position: absolute;
  left:0px;
  bottom:0px;
  background-color: ${colors.primary};
  color: ${colors.white};
  text-align: center;
`;



const HiddenContentMessage = () => (
  <MessageWrapper>
    <Heading size={7}>Confidential Information</Heading>
    <Text>
      If you need to see this section, click on the view icon at the top right of this panel.
    </Text>
  </MessageWrapper>
);

const Section = ({
  children,
  loading,
  onCloseClick,
  onEditClick,
  onHideClick,
  onShowClick,
  title,
  label,
  disabled,
  complete,
  viewState,
}) => {
  const showEditButton = onEditClick && (viewState === 'hide' || viewState === 'show');
  const showHideButton = onHideClick && viewState === 'show';
  const showShowButton = onShowClick && viewState === 'hide';
  const showCloseButton = !!onCloseClick;
  return (
    <Container disabled={disabled}>
      <Header>
        <StyledHeading label={label}>
          {title}
          {loading && <StyledLoadingSpinner />}
        </StyledHeading>
        {label && <StyledLabel>{label}</StyledLabel>}
        {complete && (<StyledIcon foregroundColor={colors.white} type="complete" onClick={onShowClick} />)}

        <Actions>
          {showShowButton && (
            <StyledIcon foregroundColor={colors.primary} type="show" onClick={onShowClick} />
          )}
          {showHideButton && (
            <StyledIcon foregroundColor={colors.primary} type="hide" onClick={onHideClick} />
          )}
          {showEditButton && (
            <StyledIcon foregroundColor={colors.primary} type="pencil" onClick={onEditClick} />
          )}
          {showCloseButton && (
            <StyledIcon foregroundColor={colors.primary} type="error" onClick={onCloseClick} />
          )}
        </Actions>
      </Header>
      {viewState === 'hide' && <HiddenContentMessage />}
      {viewState !== 'hide' && children}
    </Container>
  );
};

Section.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onHideClick: PropTypes.func,
  onShowClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  complete: PropTypes.bool,
  disabled: PropTypes.bool,
  viewState: PropTypes.oneOf(['hide', 'show', 'edit']),
};

Section.defaultProps = {
  children: null,
  loading: false,
  onCloseClick: null,
  onEditClick: null,
  onHideClick: null,
  onShowClick: null,
  viewState: 'show',
  disabled: false,
};

export default Section;
