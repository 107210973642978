import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { PortalRoot } from 'packages/portal-root';
import { colors, Modal, Section, Text } from 'shared-library';

import { TransactionDetail } from './TransactionDetail';
import TransactionRefund from './TransactionRefund';
import { PaymentContext } from '../PaymentContext';
import TransactionRow from './TransactionRow';

const RefundModal = styled(Modal)`
  width: 500px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;

const TableHead = styled.thead`
  border-bottom: 1px solid ${colors.cloud};
`;

const TableHeadRow = styled.tr``;

const TableBody = styled.tbody``;

const TableHeader = styled.th`
  color: ${colors.charcoal};
  font-size: 12px;
  font-weight: 700;
  padding: 16px 20px;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
`;

const TransactionTable = ({ openAlert, onChange }) => {
  const { transactions, viewTransaction, setViewTransaction } = useContext(PaymentContext);
  const handleCloseTransactionDetails = useCallback(() => {
    setViewTransaction(null);
  }, []);

  const [refundTransaction, setRefundTransaction] = useState(transactions[0]);
  const handleCloseRefundTransaction = useCallback(() => {
    setRefundTransaction(null);
  }, []);

  return (
    <>
      <Section title="Transaction Details">
        {!transactions.length && <Text>No recorded transactions.</Text>}
        {!!transactions.length && (
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableHeader>Transaction</TableHeader>
                <TableHeader>Type</TableHeader>
                <TableHeader>Amount</TableHeader>
                <TableHeader>Date</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader width="260px">Actions</TableHeader>
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction) => (
                <TransactionRow
                  key={`${transaction.transactionID}-row`}
                  transaction={transaction}
                  openAlert={openAlert}
                  onViewTransactionDetail={setViewTransaction}
                  onRefundTransaction={setRefundTransaction}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </Section>
      {viewTransaction && (
        <PortalRoot>
          <Modal onClose={handleCloseTransactionDetails} title="Transaction Details">
            <TransactionDetail transaction={viewTransaction} openAlert={openAlert} />
          </Modal>
        </PortalRoot>
      )}
      {refundTransaction && (
        <PortalRoot>
          <RefundModal
            onClose={handleCloseRefundTransaction}
            title={
              refundTransaction.transactionType === 'reconciliation' ||
              ['promocode', 'referral code'].includes(refundTransaction.paymentType)
                ? 'Remove Transaction'
                : 'Refund Transaction'
            }
          >
            <TransactionRefund
              onClose={handleCloseRefundTransaction}
              transaction={refundTransaction}
              openAlert={openAlert}
              onChange={onChange}
            />
          </RefundModal>
        </PortalRoot>
      )}
    </>
  );
};

TransactionTable.propTypes = {
  openAlert: PropTypes.func.isRequired,
};

export default TransactionTable;
