import React from 'react';

import {
  formatCamelCaseAsCapitalCase,
  formatCapitalizedFirstLetter,
  formatCurrency,
  formatUnixDatetime,
} from 'packages/formatters';
import { Text } from 'shared-library';

import { Column, Label, Row } from '../Layout';

import { StatusChanges } from './StatusChanges';
import InvoicePayerTransactionDetail from './InvoicePayerTransactionDetail';

export const formatPaymentType = (transaction) => {
  const { paymentType, details } = transaction;
  if (paymentType.split(/\s/).join('') === 'giftcertificateintegrated') {
    return 'Gift Certificate - Integrated';
  }
  if (paymentType === 'giftcertificate') {
    return 'Gift Certificate - Non-Integrated';
  }
  if (paymentType === 'external terminal') {
    return 'Debit/Credit Card - PIN Pad';
  }
  if (paymentType === 'stripe') {
    if (details?.paymentMethod === 'affirm') {
      return 'Affirm/Stripe';
    }
    return 'Debit/Credit Card - Manual Card';
  }
  if (paymentType === 'finance' && details?.paymentMethod) {
    return `${formatCamelCaseAsCapitalCase(details?.paymentMethod)} Installment Plan`;
  }
  if (paymentType === 'clinic') {
    return 'Partner Clinic Collected';
  }
  return formatCamelCaseAsCapitalCase(paymentType);
};

export const TransactionDetail = ({ transaction, openAlert }) => {
  const formattedTransactionTime = formatUnixDatetime(
    transaction.transactionTime || transaction.time,
  );
  const formattedPaymentType = formatPaymentType(transaction);
  const formattedPaymentMethod =
    transaction.details && formatCamelCaseAsCapitalCase(transaction.details.paymentMethod);
  const formattedAmount = formatCurrency(transaction.amount, transaction.currency);
  const formattedStatus =
    transaction.details && formatCamelCaseAsCapitalCase(transaction.details.status);
  return (
    <>
      <Row>
        <Column width="50%">
          <Label>Study ID</Label>
          <Text>{transaction.studyID}</Text>
        </Column>
        <Column width="50%">
          <Label>Transaction ID</Label>
          <Text>{transaction.transactionID}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Transaction Time</Label>
          <Text>{formattedTransactionTime}</Text>
        </Column>
        <Column>
          <Label>Status</Label>
          <Text>{formattedStatus || transaction.status}</Text>
        </Column>
        {transaction.processedByPII && (
          <Column>
            <Label>Processed By</Label>
            <Text>{transaction.processedByPII}</Text>
          </Column>
        )}
        <Column>
          <Label>Transaction Type</Label>
          <Text>{formatCamelCaseAsCapitalCase(transaction.transactionType)}</Text>
        </Column>
        <Column>
          <Label>Payment Type</Label>
          <Text>{formattedPaymentType}</Text>
        </Column>
        <Column>
          <Label>Currency</Label>
          <Text>{transaction.currency}</Text>
        </Column>
        <Column>
          <Label>Amount</Label>
          <Text>{formattedAmount}</Text>
        </Column>
        {transaction.details?.paymentMethod && (
          <Column>
            <Label>Payment Method</Label>
            <Text>{formattedPaymentMethod}</Text>
          </Column>
        )}
        {transaction.details?.referralCode && (
          <Column>
            <Label>Referral Code</Label>
            <Text>{transaction.details.referralCode}</Text>
          </Column>
        )}
        {transaction.transactionType === 'Refund' && (
          <Column>
            <Label>Linked Transaction</Label>
            <Text>{transaction.details.linkedTransaction}</Text>
          </Column>
        )}
        {transaction.details?.referenceID && (
          <Column>
            <Label>Reference No.</Label>
            <Text>{transaction.details.referenceID}</Text>
          </Column>
        )}
        {transaction.details?.cardType && (
          <Column>
            <Label>Card Type</Label>
            <Text>{formatCapitalizedFirstLetter(transaction.details.cardType)}</Text>
          </Column>
        )}
        {transaction.details?.cardLastFour && (
          <Column>
            <Label>Last 4 Digits</Label>
            <Text>{transaction.details.cardLastFour}</Text>
          </Column>
        )}
        {transaction.details?.invoicePayer && (
          <InvoicePayerTransactionDetail transaction={transaction} />
        )}
        {transaction.memo && (
          <Column>
            <Label>Notes</Label>
            <Text>{transaction.memo}</Text>
          </Column>
        )}
        {transaction.details?.giftCardNumber && (
          <Column>
            <Label>Gift Card Number</Label>
            <Text>{transaction.details.giftCardNumber}</Text>
          </Column>
        )}
        <Column>
          <Label>SKU</Label>
          <Text>{transaction.sku}</Text>
        </Column>
      </Row>
      {['cash', 'cheque', 'clinic', 'externalLender', 'invoice'].includes(
        transaction.paymentType,
      ) && <StatusChanges transaction={transaction} openAlert={openAlert} />}
    </>
  );
};
