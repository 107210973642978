import React, { useContext, useEffect, useState } from 'react';
import { formatCamelCaseAsCapitalCase } from 'packages/formatters';
import { StudyContext, PiiContext } from 'packages/booking-contexts';
import { PaymentContext } from '../PaymentContext';
import { StyledSelectInput } from './PaymentTextInputs';

const PAYMENT_OPTIONS = {
  AFFIRM: 'affirm',
  CASH: 'cash',
  CHEQUE: 'cheque',
  ETRANSFER: 'eTransfer',
  EXTERNAL_CREDIT: 'externalCredit',
  GIFT_CERTIFICATE_INTEGRATED: 'giftCertificateIntegrated',
  INVOICE: 'invoice',
  STRIPE: 'stripe',
  CLINIC: 'clinic',
  PREPAID: 'prepaid',
  PHYSICAL_TERMINAL: 'physicalTerminal',
};

const initialPaymentOptions = Object.values(PAYMENT_OPTIONS);

export const PaymentTypesSelect = () => {
  const [paymentTypesArray, setPaymentTypesArray] = useState(initialPaymentOptions);
  const { piiContext } = useContext(PiiContext);
  const { selectedLocation } = useContext(StudyContext);
  const { paymentType, paymentArgChange } = useContext(PaymentContext);

  // affirm is filtered out if it's not the location's externalLender or country is not US
  useEffect(() => {
    let notExternalLender = selectedLocation.externalLender !== PAYMENT_OPTIONS.AFFIRM;
    let notUS = !['United States', 'US'].includes(piiContext.country);
    let options =
      notExternalLender || notUS
        ? initialPaymentOptions.filter((t) => t !== PAYMENT_OPTIONS.AFFIRM)
        : initialPaymentOptions;

    setPaymentTypesArray(options);
  }, [selectedLocation.externalLender, piiContext.country]);

  const paymentTypeOptions = paymentTypesArray.map((d) => {
    if (d === 'affirm') {
      return { value: d, label: 'Affirm Finance' };
    }
    if (d === 'stripe') {
      return { value: d, label: 'Online Credit/Debit' };
    }
    if (d === 'cheque') {
      return { value: d, label: 'Cheque/Check' };
    }
    if (d === 'clinic') {
      return { value: d, label: 'Partner Clinic Collected' };
    }
    if (d === 'eTransfer') {
      return { value: d, label: 'e-Transfer' };
    }
    if (d === 'physicalTerminal') {
      return { value: d, label: 'Terminal' };
    }
    if (d === 'giftCertificateIntegrated') {
      return { value: d, label: 'Gift Certificate - Integrated' };
    }
    return {
      value: d,
      label: formatCamelCaseAsCapitalCase(d),
    };
  });

  return (
    <StyledSelectInput
      options={paymentTypeOptions}
      defaultValue={paymentTypeOptions.filter((s) => s.value === paymentType)}
      onChange={(option) => paymentArgChange({ paymentType: option.value })}
    />
  );
};
