import React from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import colors from '../../colors';

const ScrollContainer = styled(Scrollbars)`
  height: 100%;
  width: 100%;
`;

const ScrollThumb = styled.div`
  background-color: ${colors.smoke};
`;

const ScrollingPanel = ({ children, ...rest }) => (
  <ScrollContainer
    {...rest}
    renderThumbHorizontal={(props) => <ScrollThumb {...props} />}
    renderThumbVertical={(props) => <ScrollThumb {...props} />}
  >
    {children}
  </ScrollContainer>
);

export default ScrollingPanel;
