import React from 'react';
import { colors, Label, SelectInput } from 'shared-library';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Column, Row } from '../Layout';

export const StyledSelectInput = styled(SelectInput)`
  background-color: ${colors.white};
`;

export const CountryTextInput = ({ value, onChange, required }) => {
  const countries = [
    'Australia',
    'Austria',
    'Belgium',
    'Brazil',
    'Canada',
    'China',
    'Denmark',
    'Finland',
    'France',
    'Germany',
    'Hong Kong',
    'Ireland',
    'Italy',
    'Japan',
    'Luxembourg',
    'Mexico',
    'Netherlands',
    'New Zealand',
    'Norway',
    'Portugal',
    'Singapore',
    'Spain',
    'Sweden',
    'Switzerland',
    'United Kingdom',
    'United States',
  ];

  const countryOptions = countries.map((c) => ({ value: c, label: c }));
  return (
    <>
      <Label>Country*</Label>
      <StyledSelectInput
        options={countryOptions}
        defaultValue={countryOptions.filter((s) => s.value === value)}
        placeholder="United States"
        required={required}
        onChange={onChange}
        value={countryOptions.filter((s) => s.value === value)}
      />
    </>
  );
};

const STRIPE_ELEMENT_OPTIONS = {
  style: {
    base: {
      backgroundColor: colors.white,
      color: colors.ash,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSize: '14px',
      fontWeight: '600',
      '::placeholder': {
        color: colors.smoke,
      },
    },
    invalid: {
      color: colors.error,
    },
  },
};

const StripeElementContainer = styled.div`
  border: 1px solid ${colors.smoke};
  border-radius: 3px;
  padding: 8px 10px;
  background-color: ${colors.white};
`;

const CardExpiryCVC = () => (
  <>
    <Column>
      <Label>Card Expiry Date*</Label>
      <StripeElementContainer>
        <CardExpiryElement options={STRIPE_ELEMENT_OPTIONS} />
      </StripeElementContainer>
    </Column>
    <Column>
      <Label>Card Security Code*</Label>
      <StripeElementContainer>
        <CardCvcElement options={STRIPE_ELEMENT_OPTIONS} />
      </StripeElementContainer>
    </Column>
  </>
);

export const StripeElementOnly = () => (
  <Row>
    <Column>
      <Label>Card Number*</Label>
      <StripeElementContainer>
        <CardNumberElement options={STRIPE_ELEMENT_OPTIONS} />
      </StripeElementContainer>
    </Column>
    <CardExpiryCVC />
  </Row>
);

CountryTextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};
CountryTextInput.defaultProps = {
  value: '',
  onChange: () => {},
  required: false,
};
