import { TextInput } from 'shared-library';
import React, { useContext } from 'react';
import { Column } from '../Layout';
import { PaymentContext } from '../PaymentContext';

const getReferenceIdLabel = (paymentType, paymentMethod) => {
  if (paymentType === 'giftCertificate') {
    return 'Cert #';
  }
  if (paymentType === 'giftCertificateIntegrated') {
    return 'Gift Card/Voucher Number';
  }
  if (paymentType === 'cheque' || (paymentType === 'clinic' && paymentMethod === 'cheque')) {
    return 'Cheque #';
  }
  if (
    paymentType === 'externalLender' ||
    paymentType === 'externalCredit' ||
    paymentType === 'eTransfer' ||
    (paymentType === 'clinic' && ['debit', 'credit'].includes(paymentMethod))
  ) {
    return 'Confirmation Code';
  }
  if (paymentType === 'invoice') {
    return 'Invoice #';
  }
  return '';
};

export const ReferenceIdTextInput = ({ transaction, setTransaction }) => {
  const { paymentType } = useContext(PaymentContext);

  const label = getReferenceIdLabel(paymentType, transaction.paymentMethod);
  return (
    <>
      {label && (
        <Column>
          <TextInput
            label={label}
            onChange={(value) => setTransaction({ ...transaction, referenceID: value })}
            value={transaction.referenceID}
            required
          />
        </Column>
      )}
    </>
  );
};
