import { fetchAppsResource } from 'packages/apis';

export const terminalProcess = {
  handOff: { text: 'Please Hand Terminal to Member' },
  processingPayment: { text: 'Processing Payment' },
  successful: {
    text: 'Payment is Completed',
    continueButton: true,
  },
  unsuccessful: {
    text: 'Payment Unsuccessful',
    changePayment: true,
    tryAgain: true,
  },
};

export const fetchConnectionToken = async (locationID, setStripeLocationId, handleError) => {
  if (locationID) {
    const { parsedBody: thisConnectionToken } = await fetchAppsResource(
      `/payment/terminal/${locationID}`,
    );

    if (setStripeLocationId && thisConnectionToken?.location) {
      setStripeLocationId(thisConnectionToken.location);
    }
    if (!thisConnectionToken?.location && handleError) {
      handleError();
      return null;
    }
    return thisConnectionToken?.secret;
  }
};

export const discoverReaders = async (
  config,
  terminal,
  handleConnectionError,
  terminalStatus,
  setTerminalStatus,
) => {
  const discoverResult = await terminal.discoverReaders(config);
  if (discoverResult.error) {
    const { error } = discoverResult;
    const { message } = error;
    return `Failed to discover: ${message}`;
  }
  if (discoverResult.discoveredReaders.length === 0) {
    return 'Failed to discover: No available readers.';
  }
  const selectedReader = discoverResult.discoveredReaders[0];
  const connectResult = await terminal.connectReader(selectedReader);
  if (connectResult.error) {
    const { error } = connectResult;
    const { message } = error;
    return `Failed to connect: ${message}`;
  }

  setTerminalStatus('handOff');
};

export const discoverReadersAndCreatePaymentIntent = async (
  config,
  terminal,
  handleConnectionError,
  terminalStatus,
  setTerminalStatus,
  transaction,
  handleSuccessfulPayment,
) => {
  const connectionError = await discoverReaders(
    config,
    terminal,
    handleConnectionError,
    terminalStatus,
    setTerminalStatus,
  );

  if (connectionError) {
    return handleConnectionError(connectionError);
  }

  const { parsedBody: paymentIntentObj } = await fetchAppsResource(
    `/payment/terminal/${transaction.locationID}`,
    'POST',
    {
      amount: parseFloat(transaction.amount),
    },
  );
  const { client_secret: paymentIntent, id: paymentIntentId } = paymentIntentObj;

  setTimeout(() => {
    if (terminalStatus !== 'successful' && transaction.locationID) {
      fetchAppsResource(`/payment/terminal/${transaction.locationID}`, 'POST', {
        paymentIntentId,
        cancel: true,
      });
    }
  }, 60000);

  const { paymentIntent: paymentMethod } = await terminal.collectPaymentMethod(paymentIntent);
  if (!paymentMethod) {
    return handleConnectionError('Transaction cancellation initiated on PIN Pad');
  }

  const { error, status } = paymentMethod;
  if (error) {
    const { message } = error;
    return handleConnectionError(`Payment Method Error: ${message}`);
  }
  if (status !== 'succeeded') {
    setTerminalStatus('processingPayment');

    const processPaymentResult = await terminal.processPayment(paymentMethod);
    if (processPaymentResult.error) {
      const { message } = processPaymentResult.error;
      return handleConnectionError(`Process Payment Result Error: ${message}`);
    }
    if (processPaymentResult.paymentIntent) {
      return handleSuccessfulPayment(processPaymentResult.paymentIntent);
    }
  }
  if (status === 'succeeded') {
    return handleSuccessfulPayment(paymentMethod);
  }
};
