import React from 'react';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectInput from '../SelectInput';

const Layout = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledSelectInput = styled(SelectInput)`
  .react-select {
    &__control {
      border: 0;

      &:hover {
        box-shadow: none;
      }
    }

    &__menu {
      z-index: 3;
    }
  }
`;

const monthOptions = moment.months().map((label, value) => ({ label, value }));
const currentYear = moment().year();
const yearOptions = Array(currentYear - (currentYear - 100))
  .fill('')
  .map((_, idx) => currentYear - idx)
  .map((year) => ({ label: year, value: year }));
const getMonthOption = (value) => monthOptions.find((opt) => opt.value === value);
const getYearOption = (value) => yearOptions.find((opt) => opt.value === value);

const YearMonthPicker = ({ month, onMonthSelect, onYearSelect }) => (
  <Layout>
    <StyledSelectInput
      defaultValue={getMonthOption(month.month())}
      onChange={({ value }) => onMonthSelect(month, value)}
      options={monthOptions}
    />
    <StyledSelectInput
      defaultValue={getYearOption(month.year())}
      onChange={({ value }) => onYearSelect(month, value)}
      options={yearOptions}
    />
  </Layout>
);

YearMonthPicker.propTypes = {
  month: momentPropTypes.momentObj.isRequired,
  onMonthSelect: PropTypes.func.isRequired,
  onYearSelect: PropTypes.func.isRequired,
};

export default YearMonthPicker;
