import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors';
const Header = styled.div`
  display: block;
  font-family: 'Quicksand', san-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  color: ${colors.primary};
  cursor: pointer;
  padding: 0;
`;
const CollapseContainer = styled.div`
  display: block;
  position: relative;
  background-color: ${colors.riverstone};
  width: 100%;
  padding: 5px;
`;
const CollapseSection = styled.div`
  display: block;
  padding: 10px 0;
`;

interface CollapseProps {
  title: string;
  defaultOpen: boolean;
  children: PropTypes.node;
}

// eslint-disable-next-line react/prop-types
const Collapse: React.FC<CollapseProps> = ({ defaultOpen, title, children }) => {
  const [isOpened, setOpened] = useState(defaultOpen);
  const handleClick = () => setOpened(!isOpened);
  return (
    <CollapseContainer>
      <>
        <Header onClick={handleClick}>{title}</Header>
        {isOpened && (
          <CollapseSection>
            <>{children}</>
          </CollapseSection>
        )}
      </>
    </CollapseContainer>
  );
};
export default Collapse;
