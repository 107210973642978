import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import React, { useState } from 'react';
import { DateRangePicker as ReactDateRangePicker } from 'react-dates';
import { ICON_AFTER_POSITION } from 'react-dates/constants';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import styled from 'styled-components';
import colors from '../../colors';
import Icon from '../Icon';
import Label from '../Label';

const PrevIcon = styled(Icon).attrs({
  type: 'left-caret',
  foregroundColor: colors.primary,
})`
  position: absolute;
  top: 22px;
  left: 22px;
`;

const NextIcon = styled(Icon).attrs({
  type: 'right-caret',
  foregroundColor: colors.primary,
})`
  position: absolute;
  top: 22px;
  right: 22px;
`;

const StyledDateRangePicker = styled.div`
  .DateRangePicker {
    display: block;
    width: ${({ width }) => width || '100%'};
  }
  
  .DateRangePickerInput {
    display: flex;

    &:hover {
      box-shadow: 0 2px 4px 0 rgba(217, 217, 217, 0.5);
    }

    &__withBorder {
      border-color: ${colors.smoke};
      border-radius: 3px;
    }

    &__disabled {
      background-color: ${colors.pearl};
      border-color: ${colors.cloud};
  
      &:hover {
        box-shadow: none;
      }
  
      .DateRangePickerInput_arrow_svg path {
        fill: ${colors.smoke};
      }
  
      .DateRangePickerInput_calendarIcon {
        cursor: default;
      }
    }
  }

  .DateRangePickerInput_arrow {
    display: inline-flex;
    align-items: center;

    &_svg {
      path {
        fill: ${colors.ash};
      }
    }
  }

  .DateRangePickerInput_calendarIcon {
    line-height: 1;
    margin: 0;
    padding: 6px 10px;
  }

  .DateInput {
    flex: 1;
  }

  .DateInput_input {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 7px 10px 5px;

    &::placeholder {
      color: ${colors.smoke};
    }

    &__focused {
      border-bottom-color: ${colors.primary};
    }

    &__disabled {
      background-color: ${colors.pearl};
      color: ${colors.smoke};
      font-style: normal;
    }
  }

  .CalendarDay__blocked_out_of_range {
    border: 0;
    color: ${colors.smoke};
  }

  .CalendarDay__selected_span {
    background-color: ${colors.smoke};
    border-color: ${colors.smoke};
    color: ${colors.ash};
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.white};
  }

  .CalendarDay:hover {
    background-color: ${colors.primaryHover};
    border-color: ${colors.primaryHover};
    color: ${colors.white};
  }

  .CalendarDay__hovered_span {
    background-color: ${colors.smoke};
    border-color: ${colors.smoke};
    color: ${colors.ash};
  }
`;

/**
 * DateRangePicker style wrapper around react-dates library. The implementer of this component must
 * manage start and end date states internally using onChange to update it.
 */
const DateRangePicker = ({
  className,
  disabled,
  label,
  name,
  onBlur,
  onChange,
  width,
  value,
  ...rest
}) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const { startDate, endDate } = value;

  return (
    <StyledDateRangePicker className={className} name={name} onBlur={onBlur} width={width}>
      {label && (<Label>{label}</Label>)}
      <ReactDateRangePicker
        {...rest}
        customInputIcon={<Icon type="calendar" foregroundColor={disabled ? colors.primaryDisabled : colors.primary} />}
        disabled={disabled}
        endDate={endDate}
        endDateId="end-date"
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel={true}
        inputIconPosition={ICON_AFTER_POSITION}
        minimumNights={0}
        navNext={<NextIcon />}
        navPrev={<PrevIcon />}
        onDatesChange={onChange}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        startDate={startDate}
        startDateId="start-date"
        verticalSpacing={0}
      />
    </StyledDateRangePicker>
  );
};

DateRangePicker.propTypes = {
  className: PropTypes.string, // for extending with styled-components where necessary
  disabled: PropTypes.bool,
  value: PropTypes.shape({
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj,
  }).isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  width: PropTypes.string,
};

DateRangePicker.defaultProps = {
  disabled: false,
  label: null,
  name: null,
  onBlur: null,
  width: null,
};

export default DateRangePicker;
