import React, { useEffect, useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { PortalRoot } from 'packages/portal-root';
import styled from 'styled-components';
import { loadStripeTerminal } from '@stripe/terminal-js';

import { Button, Label, Text } from 'shared-library';
import { StudyContext } from 'packages/booking-contexts';
import { getEnvironment } from 'packages/locations';
import { Row, Column } from '../../Layout';
import { AmountToPay } from '../../AmountToPay';
import { TerminalModal } from '../../TerminalModal';
import { discoverReadersAndCreatePaymentIntent } from './helperFunctions';

import { fetchConnectionToken } from './helperFunctions';
import { transactionShape } from '../../propTypes';
import { PaymentContext } from '../../PaymentContext';

const RemainingBalanceText = styled(Text)`
  padding: 8px 10px;
`;

const PhysicalTerminal = ({ transaction, setTransaction, onChange }) => {
  const { selectedLocation } = useContext(StudyContext);
  const { total, recordPayment, load } = useContext(PaymentContext);

  const [showTerminalModal, setShowTerminalModal] = useState(false);
  const [terminalStatus, setTerminalStatus] = useState('handOff');

  const [errorText, setErrorText] = useState('');
  const [stripeLocationId, setStripeLocationId] = useState(null);

  const recordTransaction = async (paymentIntent) => {
    try {
      const { transaction: paymentTransactionResponse, code } = await recordPayment(
        {
          paymentIntent,
          paymentType: 'external terminal',
          amount: transaction.amount,
        },
        onChange,
      );
      if (code === 200) {
        setTransaction({ ...transaction, amount: 0 });
        setTerminalStatus('successful');
      } else {
        handleConnectionError(paymentTransactionResponse);
      }
    } catch (e) {
      handleConnectionError(e);
    }
  };

  const handleConnectionError = (newErrorText = 'Error connecting via stripe connection token') => {
    setTerminalStatus('unsuccessful');
    if (typeof newErrorText !== 'string') {
      setErrorText('An unexpected error occurred');
    } else {
      setErrorText(newErrorText);
    }
  };

  const onFetchConnectionToken = () => {
    return fetchConnectionToken(
      selectedLocation.locationID,
      setStripeLocationId,
      handleConnectionError,
    );
  };

  const getStripeTerminal = async () => {
    if (terminalStatus !== 'handOff') {
      setTerminalStatus('handOff');
    }
    if (errorText) {
      setErrorText('');
    }
    setShowTerminalModal(true);

    const StripeTerminal = await loadStripeTerminal();
    const terminal = StripeTerminal.create({
      onFetchConnectionToken,
      onUnexpectedReaderDisconnect: () => handleConnectionError(),
    });

    const config = {
      location: stripeLocationId,
      simulated: getEnvironment() !== 'PRODUCTION',
    };

    discoverReadersAndCreatePaymentIntent(
      config,
      terminal,
      handleConnectionError,
      terminalStatus,
      setTerminalStatus,
      transaction,
      recordTransaction,
    );
  };

  useEffect(() => {
    onFetchConnectionToken();
  }, []);

  const handleClickContinue = () => {
    setTerminalStatus('handOff');
    setShowTerminalModal(false);
    load();
  };

  return (
    <>
      {showTerminalModal && (
        <PortalRoot>
          <TerminalModal
            errorText={errorText}
            terminalStatus={terminalStatus}
            handleClickContinue={handleClickContinue}
            handleTryAgain={getStripeTerminal}
            closeTerminalModal={() => setShowTerminalModal(false)}
          />
        </PortalRoot>
      )}
      <Row>
        <Column>
          <Label>Remaining Balance</Label>
          <RemainingBalanceText>
            {selectedLocation?.currency?.currency} {total}
          </RemainingBalanceText>
        </Column>
      </Row>
      <Row>
        <Column>
          <AmountToPay
            transaction={transaction}
            setTransaction={setTransaction}
            selectedLocation={selectedLocation}
            total={total}
            label="Amount to Pay"
          />
        </Column>
        <Column>
          <Label>New Remaining Balance</Label>
          <RemainingBalanceText>
            {selectedLocation?.currency?.currency} {(total - transaction.amount).toFixed(2)}
          </RemainingBalanceText>
        </Column>
      </Row>
      <Row>
        <Column>
          <Button
            onClick={getStripeTerminal}
            disabled={!parseFloat(transaction.amount)}
            variant="primary"
            size="medium"
          >
            Process Payment
          </Button>
        </Column>
      </Row>
    </>
  );
};

PhysicalTerminal.propTypes = {
  transaction: transactionShape,
  setTransaction: PropTypes.func.isRequired,
};

export default PhysicalTerminal;
