import PropTypes from 'prop-types';

export const transactionShape = PropTypes.shape({
  transactionID: PropTypes.string,
  transactionType: PropTypes.string,
  paymentType: PropTypes.string,
  details: PropTypes.shape({
    paymentMethod: PropTypes.string,
    paymentType: PropTypes.string,
    referralCode: PropTypes.string,
    cardType: PropTypes.string,
    cardLastFour: PropTypes.string,
    referenceID: PropTypes.string,
    status: PropTypes.string,
    invoicePayer: PropTypes.string,
  }),
  memo: PropTypes.string,
  amount: PropTypes.string,
  transactionTime: PropTypes.number,
  status: PropTypes.string,
  refundable: PropTypes.number,
});

const promoShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

const addOnShape = PropTypes.shape({
  addonID: PropTypes.string,
  promos: PropTypes.arrayOf(promoShape),
});

const primaryShape = PropTypes.shape({
  extensions: PropTypes.arrayOf(
    PropTypes.shape({
      extensionID: PropTypes.string,
      promos: PropTypes.arrayOf(PropTypes.string),
      price: PropTypes.number,
    }),
  ),
  public: PropTypes.bool,
  price: PropTypes.number,
  scanTime: PropTypes.number,
  cleaningTime: PropTypes.number,
  referralValue: PropTypes.number,
  marketedTime: PropTypes.number,
  promos: PropTypes.arrayOf(PropTypes.string),
  skuID: PropTypes.string,
});

const addressShape = PropTypes.shape({
  name: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  lat: PropTypes.string,
  long: PropTypes.string,
});

const machineShape = PropTypes.shape({
  machineID: PropTypes.string,
  name: PropTypes.string,
  availableSKUs: PropTypes.arrayOf(PropTypes.string),
  skus: PropTypes.arrayOf(PropTypes.string),
  calendar: PropTypes.string,
});

const dayShape = PropTypes.shape({
  open: PropTypes.number,
  close: PropTypes.number,
  openStatus: PropTypes.bool,
});

const hoursShape = PropTypes.shape({
  monday: dayShape,
  tuesday: dayShape,
  wednesday: dayShape,
  thursday: dayShape,
  friday: dayShape,
  saturday: dayShape,
  sunday: dayShape,
});

export const skuShape = PropTypes.shape({
  initial: PropTypes.string,
  shortName: PropTypes.string,
  category: PropTypes.string,
  time: PropTypes.string,
  description: PropTypes.string,
  skuID: PropTypes.string,
  name: PropTypes.string,
  novaradName: PropTypes.string,
  technique: PropTypes.shape({
    female: PropTypes.string,
    male: PropTypes.string,
  }),
  extensions: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  price: PropTypes.number,
  promocodes: PropTypes.arrayOf(promoShape),
});

export const locationShape = PropTypes.shape({
  locationID: PropTypes.string,
  catalog: PropTypes.shape({
    addons: PropTypes.arrayOf(addOnShape),
    primary: PropTypes.arrayOf(primaryShape),
  }),
  address: addressShape,
  currency: PropTypes.shape({
    currency: PropTypes.string,
    currencyPrefix: PropTypes.bool,
    currencyPrefixSymbol: PropTypes.string,
    currencySuffix: PropTypes.bool,
    currencySuffixSymbol: PropTypes.string,
    currencyThousandSeparator: PropTypes.string,
  }),
  timezone: PropTypes.string,
  taxId: PropTypes.string,
  machines: PropTypes.arrayOf(machineShape),
  type: PropTypes.string,
  active: PropTypes.bool,
  assistedBooking: PropTypes.bool,
  selfServiceBooking: PropTypes.bool,
  hours: hoursShape,
  invoiceNumber: PropTypes.number,
  invoicePayer: PropTypes.string,
  stripePublishableKey: PropTypes.string,
  stripeSecretKey: PropTypes.string,
  stripeKey: PropTypes.string,
  contactInfo: PropTypes.shape({
    phoneNumber: PropTypes.string,
    faxNumber: PropTypes.string,
    email: PropTypes.string,
  }),
  systemCommunication: PropTypes.bool,
  marketingSync: PropTypes.bool,
  referralValue: PropTypes.number,
  detailedInvoice: PropTypes.bool,
  skus: PropTypes.arrayOf(skuShape),
});
