import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors';

const sizeMap = {
  small: '14px',
  medium: '16px',
};

const Span = styled.span`
  color: ${({ color }) => color};
  font-family: 'Quicksand', san-serif;
  font-size: ${({ size }) => sizeMap[size]};
  font-weight: ${({ weight }) => weight};
  line-height: 1.5;
  margin: 0 0 16px;
  ${({ align }) => align && `text-align: ${align};`}
`;

Span.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  size: PropTypes.oneOf(Object.keys(sizeMap)),
  weight: PropTypes.oneOf(['500', '600', '700']),
};

Span.defaultProps = {
  align: null,
  color: colors.charcoal,
  size: 'medium',
  weight: '500',
};

export default Span;
