import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors';

const sizes = ['70px', '50px', '38px', '30px', '24px', '20px', '16px'];

const Heading = styled.h3`
  ${({ color }) => (color ? `color: ${color};` : `color: ${colors.charcoal};`)}
  font-family: 'Quicksand', san-serif;
  font-size: ${({ size }) => sizes[size - 1]};
  font-weight: 700;
  line-height: 1.1;
  margin: 0 0 16px;

  ${({ smallCaps }) =>
    smallCaps &&
    `
    letter-spacing: 0.5px;
    font-size: 12px;
    text-transform: uppercase;
  `}
`;

Heading.propTypes = {
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7]),
  smallCaps: PropTypes.bool,
};

Heading.defaultProps = {
  size: 3,
  smallCaps: false,
};

export default Heading;
