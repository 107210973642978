import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SizeMe } from 'react-sizeme';
import Heading from '../Heading';
import Icon from '../Icon';
import Link from '../Link';
import Logo from '../Logo';
import Text from '../Text';
import colors from '../../colors';
import { fadeAndSlideInFromTop } from '../../animations';
import { utils } from 'shared-library'

const Bar = styled.header`
  background-color: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(215, 215, 215, 0.5);
  position: relative;
  z-index: 1;
  width: 100%;
`;

const InnerBar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 1440px;
  padding: 20px;
  justify-content: space-between;
`;

const Nav = styled.div`
  position: relative;
  display: flex;
`;

const NavAnchor = styled.button`
  -webkit-appearance: none;
  align-items: center;
  border: 0;
  background-color: transparent;
  color: ${colors.primary};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 0;

  ${Text} {
    color: inherit;
    margin-right: 4px;
    margin-bottom: 0;
  }

  path {
    fill: ${colors.primary};
  }

  &:hover {
    color: ${colors.primaryHover};

    path {
      fill: ${colors.primaryHover};
    }
  }

  &:hover,
  &:focus,
  &:active {
    outline: 0;

    ${Text} {
      text-decoration: underline;
    }
  }
`;

const NavMenu = styled.nav`
  animation: ${fadeAndSlideInFromTop} 0.2s ease-out forwards;
  background-color: ${colors.white};
  border-radius: 3px;
  box-shadow: 1px 1px 6px 2px rgba(225, 225, 225, 0.5);
  display: ${({ open }) => (open ? 'block' : 'none')};
  margin-top: 8px;
  padding: 24px 16px 16px;
  position: absolute;
  top: 100%;
  right: 0;
  width: 200px;
`;

const NavHeader = styled(Heading).attrs({
  smallCaps: true,
  size: 7,
})`
  margin-bottom: 0;
`;

const NavList = styled.ul`
  list-style-type: none;
  padding-left: 10px;
  margin: 8px 0 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const NavItem = styled.li`
  padding: 0;
`;

const NavMainLink = styled(Link)`
  color: ${colors.charcoal};
  padding: 18px 48px 0 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    box-shadow: none;
  }
`;

const NavLink = styled(Link)`
  color: ${colors.ash};
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0;
  margin: 0;
  text-decoration: none;

  &:hover {
    color: ${colors.gray};
    transition: 0.1s ease-in-out color;
    text-decoration: underline;
  }
`;

const Navbar = ({ onLogoClick, user }) => {
  const ref = useRef(null);

  const userAppLinks = utils.getAppLinksForUser(user);
  const [isNavOpen, setNavOpen] = useState(false);

  const handleNavAnchorClick = useCallback(() => {
    setNavOpen(!isNavOpen);
  }, [isNavOpen]);

  const handleEscape = useCallback((keyboardEvent) => {
    if (keyboardEvent.key === 'Escape') {
      setNavOpen(false);
    }
  }, []);

  const handleClickOutside = useCallback(
    (clickEvent) => {
      if (!ref.current.contains(clickEvent.target)) {
        setNavOpen(false);
      }
    },
    [ref.current],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keyup', handleEscape);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keyup', handleEscape);
    };
  }, []);

  return (
    <SizeMe>
      {({ size }) => (
        <Bar>
          <InnerBar>
            <Logo onClick={onLogoClick} />
            <Nav ref={ref}>
              {size.width >= 440 && (
                <>
                  <NavMainLink href="/booking/package">Book Now</NavMainLink>
                  <NavMainLink href="/">Prenuvo Website</NavMainLink>
                  <NavAnchor onClick={handleNavAnchorClick}>
                    <Text color={colors.primary}>
                      {user.firstname} {user.lastname}
                    </Text>
                    <Icon type="down-caret" foregroundColor={colors.primary} />
                  </NavAnchor>
                  <NavMenu open={isNavOpen}>
                    <NavHeader>Apps</NavHeader>
                    <NavList>
                      {userAppLinks.map((link) => (
                        <NavItem key={link.label}>
                          <NavLink href={link.url}>{link.label}</NavLink>
                        </NavItem>
                      ))}
                    </NavList>
                    <NavHeader>
                      {user.firstname} {user.lastname}
                    </NavHeader>
                    <NavList>
                      <NavItem>
                        <NavLink href="/user/profile">Profile</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink href="/logout">Log Out</NavLink>
                      </NavItem>
                    </NavList>
                  </NavMenu>
                </>
              )}
              {size.width < 440 && <Icon type="hamburger" />}
            </Nav>
          </InnerBar>
        </Bar>
      )}
    </SizeMe>
  );
};

Navbar.propTypes = {
  onLogoClick: PropTypes.func,
  user: PropTypes.shape({
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

Navbar.defaultProps = {
  onLogoClick: null,
};

export default Navbar;
