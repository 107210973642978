import {
  Button,
  colors,
  LoadingSpinner,
  SelectInput,
  TextInput,
} from 'shared-library';
import { Column, LineItem, Row } from './Layout';
import { fetchAppsResource } from 'packages/apis';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { PaymentContext } from './PaymentContext';

const ApplyButton = styled(Button)`
  margin-bottom: 0;
`;

export const TransactionReconciliation = ({ studyId, openAlert }) => {
  const classifications = [
    { value: 'late-cancellation', label: 'Late cancellation' },
    { value: 'no-show', label: 'No show' },
    { value: 'downgrade', label: 'Downgrade' },
    { value: 'upgrade', label:'Upgrade' },
    { value: 'recaptured', label:'Recaptured' },
    { value: 'chargeback', label: 'Chargeback' },
    { value: 'bad-debt', label: 'Bad debt' },
    { value: 'minor-reconciliation', label: 'Minor reconciliation' },
    { value: 'dated-write-off', label:'Dated Write-Off' },
    { value: 'other', label: 'Other' },
  ];

  const [amount, setAmount] = useState('');
  const [classification, setClassification] = useState('');
  const [reason, setReason] = useState('');
  const [isApplyingReconciliation, setApplyingReconciliation] = useState(false);

  const { load } = useContext(PaymentContext);

  const handleApplyAdjustment = async () => {
    const amountNum = Number(amount);
    if (isNaN(amountNum) || amountNum < -99999.0 && amountNum > 99999.0) {
      openAlert('Amount error', 'Please enter a valid amount between -99999.0 to 99999.0');
      return;
    } else if (!classification) {
      openAlert('Classification error', 'Please choose classification from the drop down');
      return;
    } else if (!reason) {
      openAlert('Reason error', 'Please enter a valid reason');
      return;
    }

    setApplyingReconciliation(true);
    try {
      await fetchAppsResource(`/payment/study/${studyId}/transaction/reconcile`, 'POST', {
        amount: amount,
        classification: classification,
        reason: reason,
      });
      openAlert('Adjustment successful!', `Adjustment successful for the amount of ${amount}`);
      setAmount('');
      setClassification('');
      setReason('');
      await load();
    } catch (error) {
      console.error(error);
      openAlert('Adjustment failed', error.message);
    }
    setApplyingReconciliation(false);
  };

  const handleChangeAmount = (v) => {
    if (v.length === 1 && v === '-') {
      document.getElementById('amount').style.backgroundColor = 'white';
      setAmount(v);
    } else {
      const value = Number(v);
      if (!isNaN(value) && value >= -99999.0 && value <= 99999.0) {
        document.getElementById('amount').style.backgroundColor = 'white';
        setAmount(v);
      } else {
        document.getElementById('amount').style.backgroundColor = 'red';
      }
    }
  };

  return (
    <>
      {/* Intentional br's for Finance Only to be a bit inconspicuous*/}
      <br /><br />
      <LineItem amount="" label="Finance Only" tiny>
        <form onSubmit={handleApplyAdjustment}>
          <Row>
            <Column grow width="auto">
              <TextInput
                id="amount"
                label="Amount"
                onChange={handleChangeAmount}
                value={amount}
              />
            </Column>
            <Column grow width="auto">
              <SelectInput
                label="Classification"
                options={classifications}
                value={
                  classification !== '' ? classifications.find((s) => s.value === classification) : null
                }
                onChange={(opt) => setClassification(opt.value)}
              />
            </Column>
          </Row>
          <Row>
            <Column grow width="auto">
              <TextInput
                label="Reason"
                placeholder="Enter a reason for reconciliation..."
                required
                onChange={setReason}
                value={reason}
              />
            </Column>
            <Column align="flex-end" width="auto">
              <ApplyButton
                disabled={isApplyingReconciliation}
                type="submit"
                variant="primary"
                size="small"
                onClick={handleApplyAdjustment}
              >
                {isApplyingReconciliation ? (
                  <LoadingSpinner color={colors.white} size="small" />
                ) : (
                  'Apply Adjustment'
                )}
              </ApplyButton>
            </Column>
          </Row>
        </form>
      </LineItem>
    </>
  );
};
