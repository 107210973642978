import React, { createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ScrollingPanel from '../ScrollingPanel';
import colors from '../../colors';

export const TabsContext = createContext(undefined);

const StyledTabsBackground = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  ${({ variant }) =>
    variant === 'primary' &&
    `
  background-color: ${colors.riverstone};
  `}
  ${({ variant }) =>
    variant === 'secondary' &&
    `
  background-color: ${colors.white};
  `}
`;

const StyledTabList = styled.div`
  background-color: ${colors.riverstone};
  display: flex;
  flex: 0;
  flex-direction: row;
  padding: 0 30px;
`;

const StyledTab = styled.button`
  -webkit-appearance: none;
  border: 0;
  color: ${colors.ash};
  cursor: pointer;
  flex: 1;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  line-height: 16px;
  outline: 0;
  padding: 16px 30px;
  position: relative;
  letter-spacing: 0.5px;
  white-space: nowrap;

  ${({ variant }) =>
    variant === 'primary' &&
    `
  background-color: ${colors.riverstone};
  font-size: 12px;
  text-transform: uppercase;
  &.active {
    background-color: ${colors.white};
    color: ${colors.charcoal};
    font-weight: 700;
  }

  &:hover:not(.active) {
    background-color: ${colors.pearl};
  }

  &:focus::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    box-shadow: 0 0 3px ${colors.primary};
    z-index: 1;
  }

  &:not(.active) + &:not(.active) {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 8px;
      bottom: 8px;
      border: 1px solid ${colors.smoke};
    }
  }

  `}

  ${({ variant }) =>
    variant === 'secondary' &&
    `
  background-color: transparent;
  text-align: left;
  font-size: 14px;
  &.active::after {
  content: '';
  display: block;
  width: 100%;
  height: fit-content;
  border-bottom: 2px solid ${colors.primary};
  box-shadow: none;
  text-transform: none;
  
  }
  &:not(.active){
  background-color: transparent;
  }
  &:focus {
    outline: 0
  }
  `}
`;

const StyledPanel = styled.div`
  background-color: ${colors.white};
  min-height: 100%;
  padding: 30px;
`;

const StyledNotification = styled.div`
  background-color: ${colors.error};
  border-radius: 50%;
  display: inline-block;
  height: 7px;
  vertical-align: middle;
  width: 7px;
`;

const TabPropTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};
const TabDefaultProps = {
  className: '',
  variant: 'primary',
};
const Tab = ({ children, className, id, variant }) => {
  const { activeTab, handleTabClick } = useContext(TabsContext);
  const isActive = activeTab === id;
  const classNames = (className || '') + (isActive ? ' active' : '');
  return (
    <StyledTab
      active={activeTab === id}
      className={classNames}
      onClick={() => handleTabClick(id)}
      variant={variant}
    >
      {children}
    </StyledTab>
  );
};
Tab.propTypes = TabPropTypes;
Tab.defaultProps = TabDefaultProps;
const PanelPropTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};
const PanelDefaultProps = {
  className: '',
};
const Panel = ({ children, className, id }) => {
  const { activeTab } = useContext(TabsContext);
  return activeTab === id ? (
    <ScrollingPanel>
      <StyledPanel className={className}>{children}</StyledPanel>
    </ScrollingPanel>
  ) : null;
};
Panel.propTypes = PanelPropTypes;
Panel.defaultProps = PanelDefaultProps;

const TabsPropTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  initialTab: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
const TabsDefaultProps = {
  className: '',
  onChange: () => {},
};
const Tabs = ({ children, className, initialTab, onChange }) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  const handleTabClick = (tabId) => {
    if (tabId === activeTab) {
      return;
    }
    setActiveTab(tabId);
    onChange(tabId);
  };
  return (
    <TabsContext.Provider value={{ activeTab, handleTabClick }}>
      <StyledTabsBackground className={className}>{children}</StyledTabsBackground>
    </TabsContext.Provider>
  );
};
Tabs.propTypes = TabsPropTypes;
Tabs.defaultProps = TabsDefaultProps;

Tabs.TabList = StyledTabList;
Tabs.Tab = Tab;
Tabs.Panel = Panel;
Tabs.Notification = StyledNotification;
Tabs.Context = TabsContext;

export default Tabs;
