import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors';

const LogoSvg = ({ className, onClick }) => (
  <svg className={className} onClick={onClick} viewBox="0 0 562 131">
    <g>
      <g>
        <g>
          <path className="st0" d="M162.21,38.51c-4.03-2.54-8.58-3.81-13.65-3.81c-2.54,0-4.98,0.37-7.33,1.12c-2.35,0.75-4.48,1.77-6.38,3.08
            c-1.9,1.31-3.51,2.82-4.81,4.53c-0.39,0.52-0.74,1.04-1.06,1.58v-5.27c0-1.27-0.41-2.33-1.23-3.19c-0.82-0.86-1.9-1.29-3.25-1.29
            c-1.27,0-2.33,0.43-3.19,1.29c-0.86,0.86-1.29,1.92-1.29,3.19v71.51c0,1.34,0.43,2.44,1.29,3.3c0.86,0.86,1.92,1.29,3.19,1.29
            c1.34,0,2.42-0.45,3.25-1.34c0.82-0.9,1.23-1.98,1.23-3.25V84.39c0.15,0.21,0.29,0.42,0.45,0.63c1.34,1.75,3,3.32,4.98,4.7
            c1.98,1.38,4.18,2.5,6.6,3.36c2.42,0.86,5.02,1.29,7.78,1.29c5,0,9.49-1.29,13.49-3.86c3.99-2.57,7.16-6.12,9.51-10.63
            c2.35-4.51,3.53-9.64,3.53-15.39c0-5.82-1.17-10.97-3.53-15.44C169.43,44.56,166.24,41.05,162.21,38.51z M164.11,75.56
            c-1.64,3.28-3.88,5.86-6.71,7.72c-2.84,1.87-6.12,2.8-9.85,2.8c-3.73,0-7.03-0.93-9.9-2.8c-2.87-1.86-5.11-4.44-6.71-7.72
            c-1.61-3.28-2.41-6.98-2.41-11.08c0-4.18,0.8-7.87,2.41-11.08c1.6-3.21,3.84-5.74,6.71-7.61c2.87-1.87,6.17-2.8,9.9-2.8
            c3.73,0,7.01,0.93,9.85,2.8c2.83,1.87,5.07,4.4,6.71,7.61c1.64,3.21,2.46,6.9,2.46,11.08C166.57,68.58,165.75,72.28,164.11,75.56
            z"/>
          <path className="st0" d="M220.91,35.32c-1.38-0.78-2.89-1.17-4.53-1.17c-2.76,0-5.3,0.5-7.61,1.51c-2.31,1.01-4.36,2.39-6.15,4.14
            c-1.79,1.75-3.27,3.73-4.42,5.93c-0.4,0.77-0.75,1.54-1.06,2.32v-7.97c0-1.27-0.41-2.33-1.23-3.19c-0.82-0.86-1.9-1.29-3.25-1.29
            c-1.27,0-2.33,0.43-3.19,1.29c-0.86,0.86-1.29,1.92-1.29,3.19v48.79c0,1.27,0.43,2.35,1.29,3.25c0.86,0.89,1.92,1.34,3.19,1.34
            c1.27,0,2.33-0.45,3.19-1.34c0.86-0.9,1.29-1.98,1.29-3.25V61.68c0-2.69,0.46-5.2,1.4-7.55c0.93-2.35,2.16-4.46,3.69-6.32
            c1.53-1.86,3.21-3.3,5.04-4.31c1.83-1.01,3.67-1.51,5.54-1.51c1.34,0,2.46,0.21,3.36,0.62c0.9,0.41,1.83,0.61,2.8,0.61
            c1.04,0,1.98-0.37,2.8-1.12c0.82-0.74,1.23-1.9,1.23-3.47C222.98,37.21,222.29,36.11,220.91,35.32z"/>
          <path className="st0" d="M279.38,64.7c0.9-0.82,1.38-1.83,1.45-3.02c-0.07-4.18-0.86-7.96-2.35-11.36c-1.49-3.39-3.49-6.3-5.99-8.73
            c-2.5-2.42-5.34-4.27-8.51-5.54c-3.17-1.27-6.51-1.9-10.02-1.9c-4.63,0-8.99,1.21-13.09,3.64c-4.1,2.42-7.44,5.93-10.02,10.52
            c-2.57,4.59-3.86,10.13-3.86,16.62c0,5.82,1.23,10.95,3.69,15.39c2.46,4.44,5.93,7.93,10.41,10.46c4.48,2.54,9.7,3.81,15.67,3.81
            c4.1,0,7.93-0.88,11.47-2.63c3.54-1.75,6.4-3.79,8.56-6.1c1.19-0.97,1.79-2.09,1.79-3.36c0-1.04-0.37-1.94-1.12-2.69
            c-0.75-0.74-1.64-1.12-2.69-1.12c-0.97,0-1.94,0.34-2.91,1.01c-0.97,1.05-2.15,2.07-3.53,3.08c-1.38,1.01-3.04,1.85-4.98,2.52
            c-1.94,0.67-4.25,1.01-6.94,1.01c-4.1,0-7.72-0.95-10.86-2.85c-3.13-1.9-5.58-4.49-7.33-7.78c-1.52-2.84-2.34-6.11-2.54-9.74
            h40.54C277.44,65.93,278.49,65.52,279.38,64.7z M241.39,47.52c1.75-1.75,3.73-3.02,5.93-3.81c2.2-0.78,4.42-1.17,6.66-1.17
            c2.76,0,5.41,0.6,7.95,1.79c2.54,1.19,4.68,2.89,6.43,5.09c1.75,2.2,2.74,4.83,2.97,7.89v0.89h-35.18
            c0.26-1.4,0.59-2.72,1.04-3.92C238.23,51.53,239.63,49.28,241.39,47.52z"/>
        </g>
        <g>
          <path className="st1" d="M341.51,36.56c-2.91-2.2-7.01-3.3-12.31-3.3c-2.61,0-5.07,0.43-7.39,1.29c-2.31,0.86-4.4,2.01-6.27,3.47
            c-1.87,1.46-3.45,3.02-4.76,4.7c-0.26,0.34-0.5,0.67-0.73,1.01V40.3c0-1.64-0.54-3-1.62-4.09c-1.08-1.08-2.41-1.62-3.97-1.62
            c-1.64,0-2.99,0.54-4.03,1.62c-1.05,1.08-1.57,2.45-1.57,4.09v47.45c0,1.64,0.52,3,1.57,4.08c1.04,1.08,2.39,1.62,4.03,1.62
            c1.57,0,2.89-0.54,3.97-1.62c1.08-1.08,1.62-2.44,1.62-4.08V57.87c0-2.69,0.65-5.09,1.96-7.22c1.3-2.13,3.11-3.84,5.43-5.15
            c2.31-1.3,5-1.96,8.06-1.96c3.28,0,5.84,0.65,7.67,1.96c1.83,1.31,3.11,3.02,3.86,5.15c0.74,2.13,1.12,4.53,1.12,7.22v29.88
            c0,1.64,0.52,3,1.57,4.08c1.04,1.08,2.39,1.62,4.03,1.62c1.57,0,2.89-0.54,3.97-1.62c1.08-1.08,1.62-2.44,1.62-4.08V57.76
            c0-4.55-0.58-8.67-1.73-12.37C346.45,41.7,344.42,38.76,341.51,36.56z"/>
          <path className="st1" d="M408.77,34.6c-1.64,0-2.99,0.54-4.03,1.62c-1.05,1.08-1.57,2.45-1.57,4.09v29.43
            c0,4.85-1.16,8.47-3.47,10.85c-2.31,2.39-5.71,3.58-10.18,3.58c-4.4,0-7.74-1.19-10.02-3.58c-2.28-2.39-3.41-6-3.41-10.85V40.3
            c0-1.64-0.54-3-1.62-4.09c-1.08-1.08-2.41-1.62-3.97-1.62c-1.64,0-2.99,0.54-4.03,1.62c-1.05,1.08-1.57,2.45-1.57,4.09v29.43
            c0,7.76,2.14,13.82,6.43,18.19c4.29,4.36,10.35,6.55,18.19,6.55c7.91,0,14.03-2.18,18.35-6.55c4.33-4.36,6.49-10.43,6.49-18.19
            V40.3c0-1.64-0.54-3-1.62-4.09C411.66,35.14,410.33,34.6,408.77,34.6z"/>
          <path className="st1" d="M474.91,34.15c-2.31-0.07-3.99,1.12-5.04,3.58l-16.52,38.54l-16.72-38.66c-0.45-1.19-1.17-2.09-2.18-2.69
            c-1.01-0.6-2.07-0.9-3.19-0.9c-1.72,0-3.08,0.56-4.08,1.68c-1.01,1.12-1.51,2.24-1.51,3.36c0,0.67,0.07,1.25,0.22,1.73
            c0.15,0.49,0.3,0.88,0.45,1.18l21.15,47.9c0.6,1.27,1.44,2.22,2.52,2.85c1.08,0.63,2.18,0.88,3.3,0.73
            c2.24-0.15,3.84-1.34,4.81-3.58l21.26-47.9c0.22-0.45,0.41-0.91,0.56-1.4c0.15-0.48,0.22-0.95,0.22-1.4c0-1.49-0.5-2.7-1.51-3.64
            C477.65,34.62,476.4,34.15,474.91,34.15z"/>
          <path className="st1" d="M543.01,48.08c-2.65-4.59-6.21-8.21-10.69-10.86c-4.48-2.65-9.51-3.97-15.11-3.97
            c-5.52,0-10.54,1.33-15.05,3.97c-4.51,2.65-8.1,6.27-10.74,10.86c-2.65,4.59-3.97,9.91-3.97,15.95c0,5.97,1.32,11.25,3.97,15.84
            c2.65,4.59,6.23,8.19,10.74,10.8c4.51,2.61,9.53,3.92,15.05,3.92c5.6,0,10.63-1.3,15.11-3.92c4.48-2.61,8.04-6.21,10.69-10.8
            c2.65-4.59,3.97-9.87,3.97-15.84C546.98,57.99,545.65,52.67,543.01,48.08z M533.33,74.55c-1.64,3.06-3.86,5.45-6.66,7.16
            c-2.8,1.72-5.95,2.57-9.46,2.57c-3.43,0-6.55-0.86-9.35-2.57c-2.8-1.72-5.04-4.1-6.72-7.16c-1.68-3.06-2.52-6.56-2.52-10.52
            c0-4.1,0.84-7.66,2.52-10.69c1.68-3.02,3.92-5.41,6.72-7.16c2.8-1.75,5.91-2.63,9.35-2.63c3.51,0,6.66,0.88,9.46,2.63
            c2.8,1.75,5.02,4.14,6.66,7.16c1.64,3.02,2.46,6.58,2.46,10.69C535.79,67.99,534.97,71.49,533.33,74.55z"/>
        </g>
      </g>
      <g>
        <path className="st1" d="M30.12,47.65c-1.99-1.68-3.87-3.26-5.4-4.69c-2.34-2.18-6-2.06-8.19,0.28s-2.06,6,0.28,8.19
          c1.74,1.63,3.73,3.3,5.84,5.08c8.49,7.15,19.05,16.05,16.79,25.13c-1.51,6.08-2.77,12.26-3.98,18.23
          c-0.61,2.99-1.21,5.98-1.85,8.96c-0.67,3.13,1.32,6.21,4.45,6.88c0.41,0.09,0.82,0.13,1.22,0.13c2.67,0,5.08-1.86,5.66-4.58
          c0.65-3.02,1.26-6.05,1.88-9.09c1.19-5.86,2.42-11.91,3.87-17.74C54.69,68.36,40.49,56.39,30.12,47.65z"/>
        <path className="st1" d="M94.91,43.25c-2.18-2.34-5.85-2.47-8.19-0.28c-1.53,1.43-3.41,3.01-5.4,4.69
          c-10.37,8.74-24.57,20.7-20.57,36.79c1.45,5.83,2.68,11.88,3.87,17.74c0.61,3.03,1.23,6.06,1.88,9.09
          c0.58,2.72,2.98,4.58,5.66,4.58c0.4,0,0.81-0.04,1.22-0.13c3.13-0.67,5.12-3.75,4.45-6.88c-0.64-2.98-1.25-5.97-1.85-8.96
          C74.77,93.9,73.51,87.72,72,81.65c-2.26-9.08,8.3-17.98,16.79-25.13c2.1-1.77,4.09-3.45,5.84-5.08
          C96.97,49.25,97.09,45.59,94.91,43.25z"/>
        <path className="st1" d="M61.64,55.69c-2.26-9.08,8.3-17.98,16.79-25.13c2.11-1.78,4.1-3.45,5.84-5.08c2.34-2.18,2.46-5.85,0.28-8.19
          c-2.18-2.34-5.85-2.47-8.19-0.28c-1.53,1.43-3.41,3.01-5.4,4.69c-5.1,4.3-11.11,9.38-15.45,15.34
          c-4.33-5.96-10.35-11.04-15.45-15.34c-1.99-1.68-3.87-3.26-5.4-4.69c-2.34-2.18-6-2.06-8.19,0.28c-2.18,2.34-2.06,6,0.28,8.19
          c1.74,1.63,3.73,3.3,5.84,5.08c8.49,7.15,19.05,16.05,16.79,25.13c-0.77,3.1,1.12,6.25,4.22,7.02c0.47,0.12,0.94,0.17,1.4,0.17
          c0.17,0,0.33-0.03,0.5-0.04c0.17,0.01,0.33,0.04,0.5,0.04c0.46,0,0.93-0.06,1.4-0.17C60.52,61.93,62.41,58.79,61.64,55.69z"/>
      </g>
    </g>
  </svg>
);

const Logo = styled(LogoSvg)`
  width: ${({ width }) => width};
  ${({ onClick }) => onClick && 'cursor: pointer;'}

  .st0 {
    fill: ${({ color }) => color ? color : colors.gray};
  }
  .st1{
    fill: ${({ color }) => color ? color : colors.primary};
  }
`;

Logo.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
};

Logo.defaultProps = {
  color: null,
  onClick: null,
  width: '170px',
};

export default Logo;
