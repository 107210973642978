import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors';

const sizeMap = {
  xsmall: '12px',
  small: '14px',
  medium: '16px',
  large: '20px',
};

const Text = styled.p`
  color: ${({ color }) => color};
  font-family: 'Quicksand', san-serif;
  font-size: ${({ size }) => sizeMap[size]};
  font-weight: ${({ weight }) => weight};
  line-height: 1.5;
  ${({ after }) => (after != undefined ? `margin: 0 0 ${after}px;` : `margin: 0 0 16px;`)}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ underlined }) => underlined && `text-decoration: underline;`}
`;

Text.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  size: PropTypes.oneOf(Object.keys(sizeMap)),
  weight: PropTypes.oneOf(['400', '500', '600', '700']),
  underlined: PropTypes.string,
  after: PropTypes.number,
};

Text.defaultProps = {
  align: null,
  color: colors.charcoal,
  size: 'medium',
  weight: '500',
  after: 16,
};

export default Text;
