import React, { useContext, useEffect, useState } from 'react';
import { getPatientInformation } from '../paymentFunctions';
import { PaymentContext } from '../PaymentContext';
import StatusChange from './StatusChange';

const methods = [
  { value: 'cash', label: 'Cash' },
  { value: 'cheque', label: 'Cheque' },
  { value: 'credit', label: 'Credit' },
  { value: 'debit', label: 'Debit' },
];

export const StatusChanges = ({ transaction, openAlert }) => {
  const { paymentType } = transaction;
  const { updatePayment, load, setViewTransaction } = useContext(PaymentContext);
  const [disabledPaid, setDisabledPaid] = useState(!(transaction.issued || transaction.paid));
  const [disabledDeposit, setDisabledDeposit] = useState(
    (paymentType === 'invoice' && !transaction.paid) || false,
  );

  useEffect(() => {
    const newDisabledPaid = !(transaction.issued || transaction.paid);
    if (newDisabledPaid !== disabledPaid) {
      setDisabledPaid(newDisabledPaid);
    }
    const newDisableDeposit = (paymentType === 'invoice' && !transaction.paid) || false;
    if (newDisableDeposit !== disabledDeposit) {
      setDisabledDeposit(newDisableDeposit);
    }
  }, [transaction]);

  const handleSelected = async (amount, details, status, setProcessedBy) => {
    try {
      const paymentObj = {
        amount,
        details,
        paymentType,
        transactionID: transaction.transactionID,
      };
      const { transaction: transactionResponse, errorMessage } = await updatePayment(paymentObj);
      if (errorMessage) {
        openAlert('Payment Error', errorMessage);
        return { errorMessage };
      }
      if (transactionResponse[status]?.processedBy) {
        const { firstname, lastname } = await getPatientInformation(
          transactionResponse[status].processedBy,
        );
        setProcessedBy(`${firstname} ${lastname}`);
      }
      setViewTransaction(transactionResponse);
      load();
      return {};
    } catch ({ data }) {
      if (data?.errorMessage) {
        openAlert('Payment Error', data.errorMessage);
        return { errorMessage: data.errorMessage };
      }
    }
  };

  return (
    <>
      {paymentType === 'invoice' && (
        <>
          <StatusChange transaction={transaction} status="issued" handleSelected={handleSelected} />
          <StatusChange
            disabled={disabledPaid}
            transaction={transaction}
            status="paid"
            handleSelected={handleSelected}
            methods={methods}
            key={`paid-${disabledPaid}`}
          />
        </>
      )}
      <StatusChange
        disabled={disabledDeposit}
        transaction={transaction}
        status="deposit"
        handleSelected={handleSelected}
        key={`deposit-${disabledDeposit}`}
      />
    </>
  );
};
