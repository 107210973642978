import React, { useState } from 'react';
import {
  CheckboxInput,
  CurrencyInput,
  DatePicker,
  SelectInput,
  Text,
  TextInput,
} from 'shared-library';
import { formatCurrency, formatUnixDate, titleCase } from 'packages/formatters';
import moment from 'moment';
import { Column, Label, Row } from '../Layout';
import { ReferenceIdTextInput } from '../PaymentOptions/ReferenceIdTextInput';

const StatusCheckbox = ({ formatStatusTitle, status, selected, handleSelected, disabled }) => (
  <Column>
    <Label>{formatStatusTitle(status)}?</Label>
    <CheckboxInput
      id={status}
      label="Yes"
      name=""
      checked={!!selected}
      onChange={handleSelected}
      disabled={disabled}
    />
  </Column>
);

const StatusChange = ({ transaction, status, handleSelected, methods, disabled }) => {
  const [selected, setSelected] = useState(transaction[status]);
  const [amount, setAmount] = useState(transaction[status]?.amount || transaction.amount);
  const [statusTime, setStatusTime] = useState(
    transaction[status]?.transactionTime
      ? moment(transaction[status]?.transactionTime * 1000)
      : moment(),
  );
  const [method, setMethod] = useState(transaction[status]?.paymentMethod);
  const [referenceId, setReferenceId] = useState(transaction[status]?.referenceID);
  const [processedBy, setProcessedBy] = useState(transaction[status]?.processedByPII);
  const formatStatusTitle = () => {
    if (status === 'deposit') {
      return 'Deposited';
    }
    return titleCase(status);
  };

  const formatReference = () => {
    return method === 'cheque' ? 'Cheque Number' : 'Confirmation Code';
  };

  const getStatus = (updateSelected) => {
    if (status === 'paid') {
      return updateSelected ? 'paid' : 'notPaid';
    }
    if (status === 'deposit') {
      return updateSelected ? 'deposited' : 'notDeposited';
    }
    if (status === 'issued') {
      return updateSelected ? 'issued' : 'notIssued';
    }
  };

  const setSelectedAndHandle = async () => {
    const updateSelected = !selected;
    setSelected(updateSelected);
    const newStatus = getStatus(updateSelected);
    const details = {
      status: newStatus,
      transactionTime: !selected ? statusTime.unix() : '',
      paymentMethod: method,
      referenceID: referenceId,
    };
    const { errorMessage } = await handleSelected(amount, details, status, setProcessedBy);
    if (errorMessage) {
      setSelected(!updateSelected);
    }
  };

  const handleReferenceId = (transaction) => {
    const { referenceID } = transaction;
    setReferenceId(referenceID);
  };

  return (
    <>
      <Row>
        <Column>
          <Label>{formatStatusTitle(status)} Amount</Label>
          {!selected ? (
            <CurrencyInput
              value={amount}
              placeholder={`${status} Amount`}
              width="100%"
              onChange={({ value }) => setAmount(value || '')}
              displayType="input"
              fixedDecimalScale
              decimalScale={2}
              allowNegative={false}
              disabled={disabled}
            />
          ) : (
            <Text>{formatCurrency(amount, transaction.currency)}</Text>
          )}
        </Column>
        <Column>
          {!selected ? (
            <DatePicker
              label={`Date ${formatStatusTitle(status)}`}
              isOutsideRange={() => false}
              value={statusTime}
              onChange={setStatusTime}
              disabled={disabled}
              id={`${status}-date-picker`}
            />
          ) : (
            <>
              <Label>{`Date ${formatStatusTitle(status)}`}</Label>
              <Text>{formatUnixDate(statusTime.unix())}</Text>
            </>
          )}
        </Column>
        {status !== 'deposit' ? (
          <Column />
        ) : (
          <StatusCheckbox
            selected={selected}
            handleSelected={setSelectedAndHandle}
            status={status}
            formatStatusTitle={formatStatusTitle}
            disabled={disabled}
          />
        )}
      </Row>
      {methods && (
        <Row>
          <Column>
            {selected ? (
              <>
                <Label>Method</Label>
                <Text>{titleCase(method)}</Text>
              </>
            ) : (
              <SelectInput
                label="Method"
                options={methods}
                value={methods.find((s) => s.value === method)}
                onChange={({ value }) => setMethod(value)}
                disabled={disabled}
              />
            )}
          </Column>
          <Column>
            {method &&
              method !== 'cash' &&
              (selected ? (
                <>
                  <Label>{formatReference()}</Label>
                  <Text>{referenceId}</Text>
                </>
              ) : (
                <TextInput
                  label={formatReference()}
                  onChange={setReferenceId}
                  value={referenceId}
                  required
                  disabled={disabled}
                />
              ))}
          </Column>
          <StatusCheckbox
            selected={selected}
            handleSelected={setSelectedAndHandle}
            status={status}
            formatStatusTitle={formatStatusTitle}
            disabled={disabled}
          />
        </Row>
      )}
      {status === 'issued' && (
        <Row>
          <ReferenceIdTextInput
            transaction={{ referenceID: referenceId }}
            setTransaction={handleReferenceId}
          />
          <Column />
          <Column>
            <StatusCheckbox
              selected={selected}
              handleSelected={setSelectedAndHandle}
              status={status}
              formatStatusTitle={formatStatusTitle}
              disabled={disabled}
            />
          </Column>
        </Row>
      )}
      <Row>
        <Column>
          <Label>{status === 'paid' ? 'Payment Processed' : formatStatusTitle(status)} By</Label>
          <Text>{processedBy}</Text>
        </Column>
      </Row>
    </>
  );
};

export default StatusChange;
