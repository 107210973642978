export default {
  primary: '#2ec4b6',
  primaryHover: '#228f85',
  primaryDisabled: 'rgba(46, 196, 182, 0.2)',
  secondary: '#ff6a56',
  secondaryHover: '#e6513d',
  secondaryDisabled: 'rgba(255, 106, 86, 0.2)',
  accentBlue: '#0080ff',
  accentPurple: '#8a40f6',
  white: '#ffffff',
  pearl: '#fafafa',
  riverstone: '#f2f3f7',
  aliceblue: '#f9fafb',
  cloud: '#f0f0f0',
  smoke: '#dddddd',
  gray: '#808080',
  ash: '#666666',
  charcoal: '#4c4c4c',
  success: '#39cb7b',
  error: '#f95541',
  warning: '#f9c107',
};
