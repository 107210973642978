import { fetchPiiResource } from 'packages/apis';

export const getPatientInformation = async (prenuvoID) => {
  const { parsedBody } = await fetchPiiResource(`/api/v1/userV2/${prenuvoID}`);
  const {
    firstname,
    lastname,
    address,
    city,
    state,
    country,
    postalCode,
    phone_number,
    email,
    gender,
  } = parsedBody;
  return {
    firstname,
    lastname,
    address,
    city,
    state,
    country,
    postalCode,
    phone_number,
    email,
    gender,
  };
};
