import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '../../colors';

const Button = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  -webkit-appearance: none;
  border: 0;
  border-radius: 4px;
  cursor: default;
  display: inline-block;
  font-family: Quicksand, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 12px 60px;
  margin-bottom: 16px;
  text-decoration: none;
  margin-right:5px;
  position:relative;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 3px ${colors.primaryHover};
  }

  &:disabled {
    cursor: default;
  }

  ${({ variant }) =>
    variant === 'primary' &&
    `
    background-color: ${colors.primary};
    color: ${colors.white};

    &:hover{
      background-color: ${colors.primaryHover};
    }

    &:disabled {
      background-color: ${colors.primaryDisabled};
    }
  `}

  ${({ variant }) =>
    variant === 'secondary' &&
    `
    background-color: ${colors.secondary};
    color: ${colors.white};

    &:hover,
    &:focus {
      background-color: ${colors.secondaryHover};
    }

    &:disabled {
      background-color: ${colors.secondaryDisabled};
    }
  `}

  ${({ variant }) =>
    variant === 'outline' &&
    `
    background-color: ${colors.white};
    border: 1px solid ${colors.primary};
    color: ${colors.primary};

    &:hover,
    &:focus {
      border-color: ${colors.primaryHover};
      color: ${colors.primaryHover};
    }

    &:disabled {
      border-color: ${colors.primaryDisabled};
      color: ${colors.primaryDisabled};
    }
  `}

  ${({ size }) =>
    size === 'xsmall' &&
    `
    font-size: 14px;
    line-height: 18px;
    padding: 6px 20px;
  `}

  ${({ size }) =>
    size === 'micro' &&
    `
    font-size: 14px;
    line-height: 18px;
    padding: 4px 22px;
    margin-bottom: 0px;
  `}

  ${({ size }) =>
    size === 'selectheight' &&
    `
    font-size: 14px;
    line-height: 18px;
    padding: 10px 22px;
  `}

  ${({ size }) =>
    size === 'small' &&
    `
    font-size: 16px;
    line-height: 20px;
    padding: 8px 42px;
  `}

  ${({ size }) =>
    size === 'medium' &&
    `
    font-size: 16px;
    line-height: 20px;
    padding: 13px 65px;
  `}

  ${({ size }) =>
    size === 'large' &&
    `
    font-size: 16px;
    line-height: 20px;
    padding: 13px 118px;
  `}
`;

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'outline']),
  size: PropTypes.oneOf(['xsmall', 'micro', 'selectheight', 'small', 'medium', 'large']),
};

Button.defaultProps = {
  variant: 'primary',
  size: 'medium',
};

export default Button;
