import React, { createContext, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../colors';
import Icon from '../Icon';
import Label from '../Label';
import Tag from '../Tag';

const TagContext = createContext(undefined);

const Checkbox = styled.div`
  background-color: ${colors.white};
  border-radius: 3px;
  border: 1px solid ${colors.ash};
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  overflow: hidden;

  ${({ checked }) => checked && css`
    background-color: ${colors.success};
    border: 0;
  `}

  svg {
    transform: translate(-2px, -2px);
  }
`;

const StyledTag = styled(Tag)`
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  padding: 4px;
`;

const TagGroup = ({ children, initialSelected, label, onChange }) => {
  const [selectedTags, setSelectedTags] = useState(initialSelected);
  const handleSelectChange = tag => {
    const isTagSelected = selectedTags.some(selectedTag => selectedTag.name === tag.name);
    const newSelection = isTagSelected
      ? selectedTags.filter(item => item.name !== tag.name)
      : [...selectedTags, tag];
    setSelectedTags(newSelection);
    onChange(newSelection);
  };
  return (
    <TagContext.Provider value={{ selectedTags, handleSelectChange }}>
      {label && <Label>{label}</Label>}
      {children}
    </TagContext.Provider>
  );
};

const TagInput = ({ className, name, label }) => {
  const { selectedTags, handleSelectChange } = useContext(TagContext);
  const isSelected = selectedTags.some(tag => tag.name === name);
  const handleTagClick = () => {
    handleSelectChange({ name, label });
  };
  return (
    <StyledTag
      className={className}
      color={isSelected ? colors.success : colors.ash}
      onClick={handleTagClick}
    >
      <Checkbox checked={isSelected}>
        {isSelected && <Icon size="small" type="complete" />}
      </Checkbox>
      {label}
    </StyledTag>
  );
};

TagGroup.Input = TagInput;

export default TagGroup;
