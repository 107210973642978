import React, { createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../colors';

const TagContext = createContext(undefined);

const Tag = styled.span`
  border-radius: 3px;
  border: 1px solid currentColor;
  color: ${({ color }) => color};
  display: inline-block;
  font-size: ${({ size }) => (size === 'small' ? '10px' : '12px')};
  font-weight: ${({ size }) => (size === 'small' ? '700' : '600')};
  line-height: 12px;
  padding: ${({ size }) => (size === 'small' ? '2px' : '6px')} 5px;
  margin: 0 ${({ size }) => (size === 'small' ? '5px' : '15px')} 5px 0;
`;

Tag.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
};

Tag.defaultProps = {
  color: colors.charcoal,
  size: 'medium',
};

export default Tag;
