import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { ICON_AFTER_POSITION } from 'react-dates/constants';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import styled, { css } from 'styled-components';
import colors from '../../colors';
import Icon from '../Icon';
import Label from '../Label';
import YearMonthPicker from './YearMonthPicker';

const PrevIcon = styled(Icon).attrs({
  type: 'left-caret',
  foregroundColor: colors.primary,
})`
  position: absolute;
  top: 22px;
  left: 22px;
  &:hover {
    path {
      fill: ${colors.primaryHover};
    }
  }
`;

const NextIcon = styled(Icon).attrs({
  type: 'right-caret',
  foregroundColor: colors.primary,
})`
  position: absolute;
  top: 22px;
  right: 22px;
  &:hover {
    path {
      fill: ${colors.primaryHover};
    }
  }
`;

const MiddlePrevIcon = styled(PrevIcon)`
  top: -2px;
  left: 23px;
  &:hover {
    path {
      fill: ${colors.primaryHover};
    }
  }
`;

const MiddleNextIcon = styled(NextIcon)`
  top: -2px;
  right: 23px;
  &:hover {
    path {
      fill: ${colors.primaryHover};
    }
  }
`;

const StyledDatePicker = styled.div`
  width: ${({ width }) => width || '100%'};

  .SingleDatePicker {
    display: block;
  }

  .SingleDatePickerInput {
    display: flex;

    &:hover {
      box-shadow: 0 2px 4px 0 rgba(217, 217, 217, 0.5);
    }

    &__withBorder {
      border-color: ${colors.smoke};
      border-radius: 3px;
    }

    &__disabled {
      background-color: ${colors.pearl};

      &:hover {
        box-shadow: none;
      }

      .SingleDatePickerInput_calendarIcon {
        cursor: default;
      }
    }
  }

  .SingleDatePickerInput_calendarIcon {
    line-height: 1;
    margin: 0;
    padding: 6px 10px;
  }

  .DateInput {
    flex: 1;
  }

  .DateInput_input {
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 7px 10px 5px;

    &::placeholder {
      color: ${colors.smoke};
    }

    &__focused {
      border-bottom-color: ${colors.primary};
    }

    &__disabled {
      background-color: ${colors.pearl};
      color: ${colors.smoke};
      font-style: normal;
    }
  }

  .CalendarDay__blocked_out_of_range {
    border: 0;
    color: ${colors.smoke};
  }

  .CalendarDay__selected {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.white};
  }

  .CalendarDay:hover {
    background-color: ${colors.primaryHover};
    border-color: ${colors.primaryHover};
    color: ${colors.white};
  }

  ${({ yearPicker }) =>
    yearPicker &&
    css`
      .CalendarMonth_caption {
        padding: 8px 0;
      }

      .DayPicker_weekHeaders {
        display: none;
      }
    `}
`;

/**
 * DatePicker style wrapper around react-dates library. The implementer of this component must
 * manage date state internally using onDateChange to update it.
 */
const DateRangePicker = ({
  className,
  disabled,
  label,
  name,
  onBlur,
  onChange,
  onMonthChange,
  width,
  value,
  yearPicker,
  numberOfMonths,
  renderMonthContents,
  futureYears,
  ...rest
}) => {
  const [isFocused, setFocused] = useState(false);
  return (
    <StyledDatePicker
      className={className}
      name={name}
      onBlur={onBlur}
      width={width}
      yearPicker={yearPicker}
    >
      {label && <Label>{label}</Label>}
      <SingleDatePicker
        {...rest}
        customInputIcon={
          <Icon
            type="calendar"
            foregroundColor={disabled ? colors.primaryDisabled : colors.primary}
          />
        }
        date={value}
        disabled={disabled}
        focused={isFocused}
        hideKeyboardShortcutsPanel
        inputIconPosition={ICON_AFTER_POSITION}
        navNext={yearPicker ? () => {} : futureYears ? <MiddleNextIcon /> : <NextIcon />}
        navPrev={yearPicker ? () => {} : futureYears ? <MiddlePrevIcon /> : <PrevIcon />}
        numberOfMonths={numberOfMonths || (yearPicker ? 1 : 2)}
        onDateChange={onChange}
        onPrevMonthClick={onMonthChange}
        onNextMonthClick={onMonthChange}
        onFocusChange={({ focused }) => setFocused(focused)}
        verticalSpacing={0}
        renderMonthElement={
          renderMonthContents ? renderMonthContents : yearPicker ? YearMonthPicker : null
        }
      />
    </StyledDatePicker>
  );
};

DateRangePicker.propTypes = {
  className: PropTypes.string, // for extending with styled-components where necessary
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onMonthChange: PropTypes.func,
  width: PropTypes.string,
  value: momentPropTypes.momentObj,
  yearPicker: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  futureYears: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  className: null,
  disabled: false,
  label: null,
  name: null,
  width: null,
  yearPicker: false,
  numberOfMonths: 0,
  futureYears: false,
};

export default DateRangePicker;
