import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../Icon';
import colors from '../../colors';

const InputContainer = styled.div`
  align-items: center;
  display: inline-flex;
  margin: 9px 32px 9px 0;
  position: relative;
`;

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  -webkit-appearance: none;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Label = styled.label`
  color: ${({ disabled }) => (disabled && colors.smoke) || colors.charcoal};
  cursor: pointer;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding-left: 25px;
`;

const CheckboxFill = styled(Icon).attrs({
  type: 'checkbox-filled',
})`
  position: relative;
  vertical-align: baseline;
`;

const Checkbox = styled.div`
  border-radius: 3px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  height: 20px;
  width: 20px;
  pointer-events: none;

  &::before {
    background-color: ${colors.white};
    border: 1px solid ${colors.smoke};
    border-radius: 3px;
    content: '';
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }

  ${({ highlighted }) =>
    highlighted &&
    `
    ${Input} + & {
      box-shadow: 0 0 10px ${colors.secondary};
    }
  `}

  ${Input}:checked + &::before {
    background-color: ${colors.primary};
    border: 1px solid ${colors.primary};
  }

  ${Input}:focus + &::before {
    border: 1px solid ${colors.primaryHover};
    box-shadow: 0 0 3px ${colors.primaryHover};
  }

  ${Input}:focus + & {
    box-shadow: 0 0 3px ${colors.primaryHover};
  }

  ${Input}:disabled + & {
    color: ${colors.smoke};
    cursor: default;
  }

  ${Input}:disabled:checked + &::before {
    border-color: ${colors.primaryDisabled};
  }
`;

const CheckboxInput = ({
  checked,
  className,
  disabled,
  id,
  label,
  name,
  onChange,
  highlighted,
}) => (
  <InputContainer className={className}>
    <Input checked={checked} id={id} name={name} onChange={onChange} disabled={disabled} />
    <Checkbox highlighted={highlighted}>
      {checked && <CheckboxFill type="checkbox-filled" />}
    </Checkbox>
    <Label htmlFor={id} disabled={disabled}>
      {label || name}
    </Label>
  </InputContainer>
);

CheckboxInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  highlighted: PropTypes.bool,
};

CheckboxInput.defaultProps = {
  className: null,
  disabled: false,
  label: null,
  name: null,
  highlighted: false,
};

export default CheckboxInput;
