import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const REACT_ROOT_ID = 'react-root';
const DEFAULT_PORTAL_ROOT_ID = 'portal-root';

interface PortalRootProps {
  children: React.ReactElement;
  target?: HTMLElement;
}

/**
 * Creates a React Portal which displays any children given to it.
 * Mounts at a target node or creates a sibling next to the #react-root node
 */
const PortalRoot: React.FC<PortalRootProps> = ({ children, target }: PortalRootProps) => {
  const [targetElement, setTargetElement] = useState(target);
  useEffect(() => {
    if (targetElement) {
      return;
    }
    const defaultTargetElement = document.getElementById(DEFAULT_PORTAL_ROOT_ID);
    if (defaultTargetElement) {
      setTargetElement(defaultTargetElement);
      return;
    }
    const rootElement = document.getElementById(REACT_ROOT_ID);
    const generatedTargetElement = document.createElement('div');
    generatedTargetElement.id = DEFAULT_PORTAL_ROOT_ID;
    setTargetElement(generatedTargetElement);
    rootElement.insertAdjacentElement('afterend', generatedTargetElement);
  }, []);
  return targetElement ? ReactDOM.createPortal(children, targetElement) : null;
};

PortalRoot.defaultProps = {
  target: null,
};

export default PortalRoot;
