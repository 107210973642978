/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from '../../colors';
import Label from '../Label';

const Container = styled.div`
  width: ${({ width }) => width};
`;

const StyledCurrencyFormat = styled(CurrencyFormat)`
  -webkit-appearance: none;
  border: 1px solid ${colors.smoke};
  border-radius: 3px;
  color: ${colors.ash};
  display: block;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 10px;
  width: 100%;

  ::placeholder {
    color: ${colors.smoke};
  }

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(217, 217, 217, 0.5);
  }

  &:active,
  &:focus {
    color: ${colors.charcoal};
    border-color: ${colors.primary};
    outline: 0;
  }

  &:disabled {
    background-color: ${colors.pearl};
    border-color: ${colors.cloud};
    box-shadow: none;
    color: ${colors.smoke};
    cursor: default;
  }
`;

const CurrencyInput = ({ width, label, onChange, ...inputProps }) => (
  <Container width={width}>
    {label && <Label htmlFor={inputProps.id}>{label}</Label>}
    <StyledCurrencyFormat onValueChange={(values) => onChange(values)} {...inputProps} />
  </Container>
);

CurrencyInput.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CurrencyInput.defaultProps = {
  width: '100%',
  label: null,
};

export default CurrencyInput;
