import styled from 'styled-components';
import Text from '../Text';
import colors from '../../colors';

const Link = styled(Text).attrs({
  as: 'a',
})`
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  color: ${colors.primary};
  cursor: pointer;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: ${colors.primaryHover};
  }

  &:focus {
    border-radius: 3px;
    box-shadow: 0 0 3px ${colors.primaryHover};
    color: ${colors.primaryHover};
    outline: 0;
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: ${colors.charcoal};
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    &:hover {
      color: ${colors.charcoal};
    }
  `}
`;

export default Link;
