import styled from 'styled-components';
import colors from '../../colors';

const Card = styled.div`
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : `background-color: ${colors.white};`}
  border: 1px solid ${colors.smoke};
  border-radius: 3px;
  display: block;
  margin-bottom: 16px;
  padding: 16px;

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export default Card;
