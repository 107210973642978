/* eslint-disable react/jsx-props-no-spreading */
import { StudyContext } from 'packages/booking-contexts';
// TODO: Tabs needs to be changed after ui-library converted in FD
import { Tabs } from 'packages/ui-library';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState, Suspense, lazy } from 'react';
import { useHistory } from 'react-router-dom';
import { colors, Button, Label, LoadingLogo, Modal, Text, Section } from 'shared-library';
import styled from 'styled-components';

import { Column, Row } from '../Layout';
import { PaymentContext } from '../PaymentContext';

import { PaymentTypesSelect } from './PaymentTypesSelect';

const AffirmCheckout = lazy(() => import('./AffirmCheckout'));
const CashPayment = lazy(() => import('./CashPayment'));
const ChequeColumns = lazy(() => import('./ChequeColumns'));
const ClinicPayment = lazy(() => import('./ClinicPayment'));
const ETransferPayment = lazy(() => import('./eTransferPayment'));
const ExternalCreditColumns = lazy(() => import('./ExternalCreditColumns'));
const ExternalLenderPayment = lazy(() => import('./ExternalLenderPayment'));
const GiftCertificate = lazy(() => import('./GiftCertificate'));
const IntegratedGiftCertificate = lazy(() => import('./IntegratedGiftCertificate'));
const InvoicePayment = lazy(() => import('./InvoicePayment'));
const PaymentButton = lazy(() => import('./PaymentButton'));
const PhysicalTerminal = lazy(() => import('./PhysicalTerminal'));
const PrepaidPayment = lazy(() => import('./PrepaidPayment'));
const StripeElements = lazy(() => import('./StripeElements'));

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalButton = styled(Button)`
  margin-top: 15px;
  align-self: flex-end;
`;

const PaymentOptions = ({ openAlert, onChange }) => {
  const [transaction, setTransaction] = useState({ amount: 0 });
  const {
    paymentType,
    total,
    showSafetyQuestionsDialog,
    setShowSafetyQuestionsDialog,
    price
  } = useContext(PaymentContext);
  const { selectedLocation, studyID } = useContext(StudyContext);
  const handleTabClickFallBack = () => {};
  const { handleTabClick } = useContext(Tabs.Context) || handleTabClickFallBack;

  const history = useHistory();
  const handleRoutChange = useCallback((tabId, toggleEditOnStudyTab) => {
    history.replace({
      ...history.location,
      pathname: `/study/${studyID}/${tabId}`,
      state: { toggleEditOnStudyTab },
    });
  }, []);

  useEffect(() => {
    setTransaction({
      amount:
        // eslint-disable-next-line no-nested-ternary
        selectedLocation.type === 'whitelabel' && paymentType === 'invoice'
          ? parseFloat(total)
          : paymentType === 'giftCertificateIntegrated'
          ? price
          : 0,
      paymentMethod: paymentType === 'externalLender' ? 'paybright' : '',
      locationID: selectedLocation.locationID,
    });
  }, [paymentType]);

  useEffect(() => {
    if (selectedLocation.type === 'whitelabel' && paymentType === 'invoice') {
      setTransaction({ amount: parseFloat(total) });
    }
  }, [total]);

  const navigateToStudyTab = () => {
    setShowSafetyQuestionsDialog(false);
    handleTabClick('study-details');
    handleRoutChange('study-details', true);
  };

  return (
    <Section title="Make a Payment">
      <Row>
        <Column>
          <Label>Payment Type</Label>
          <PaymentTypesSelect />
        </Column>
        {paymentType === 'affirm' && (
          <Column>
            <Label>Affirm Link</Label>
            <Text color={colors.primary}>m.affirm.com/prenuvo</Text>
          </Column>
        )}
        <Column>
          <Label>Scan Location</Label>
          <Text>{selectedLocation?.address?.name}</Text>
        </Column>
      </Row>
      <Suspense fallback={<LoadingLogo />}>
        {paymentType === 'stripe' && (
          <StripeElements openAlert={openAlert} transaction={transaction} onChange={onChange} />
        )}
        {paymentType === 'physicalTerminal' && (
          <PhysicalTerminal
            transaction={transaction}
            setTransaction={setTransaction}
            onChange={onChange}
          />
        )}
        {paymentType === 'affirm' && (
        <AffirmCheckout openAlert={openAlert} onChange={onChange} />
        )}
        {paymentType === 'cash' && (
          <CashPayment transaction={transaction} setTransaction={setTransaction} />
        )}
        {paymentType === 'cheque' && (
          <ChequeColumns transaction={transaction} setTransaction={setTransaction} />
        )}
        {paymentType === 'eTransfer' && (
          <ETransferPayment transaction={transaction} setTransaction={setTransaction} />
        )}
        {paymentType === 'externalCredit' && (
          <ExternalCreditColumns transaction={transaction} setTransaction={setTransaction} />
        )}
        {paymentType === 'externalLender' && (
          <ExternalLenderPayment transaction={transaction} setTransaction={setTransaction} />
        )}
        {paymentType === 'giftCertificate' && (
          <GiftCertificate setTransaction={setTransaction} transaction={transaction} />
        )}
        {paymentType === 'giftCertificateIntegrated' && (
          <IntegratedGiftCertificate setTransaction={setTransaction} transaction={transaction} />
        )}
        {paymentType === 'invoice' && (
          <InvoicePayment transaction={transaction} setTransaction={setTransaction} />
        )}
        {paymentType === 'clinic' && (
          <ClinicPayment transaction={transaction} setTransaction={setTransaction} />
        )}
        {paymentType === 'prepaid' && (
          <PrepaidPayment transaction={transaction} setTransaction={setTransaction} />
        )}
        {!['stripe', 'physicalTerminal', 'affirm'].includes(paymentType) && (
          <PaymentButton transaction={transaction} openAlert={openAlert} onChange={onChange} />
        )}
        {showSafetyQuestionsDialog ? (
          <Modal
            title="Safety questions need to be completed"
            width="500px"
            onClose={() => setShowSafetyQuestionsDialog(false)}
          >
            <ModalContainer>
              <Text>This member has not completed their mandatory safety questions.</Text>
              <ModalButton variant="primary" size="small" onClick={navigateToStudyTab}>
                Answer Questions
              </ModalButton>
            </ModalContainer>
          </Modal>
        ) : null}
      </Suspense>
    </Section>
  );
};

PaymentOptions.propTypes = {
  openAlert: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

PaymentOptions.defaultProps = {
  onChange: () => {},
};

export default PaymentOptions;
