import React, { useContext } from 'react';
import InvoiceSummary from "./InvoiceSummary";
import { StudyContext, PiiContext, BookingContext } from 'packages/booking-contexts';
import { PaymentContext } from '../PaymentContext';

export const InvoiceSummaryWrapper = () => {
    const studyContext = useContext(StudyContext);
    const piiContext = useContext(PiiContext);
    const bookingContext = useContext(BookingContext);
    const paymentContext = useContext(PaymentContext);

    return (
        <InvoiceSummary
            studyContext={studyContext}
            piiContextInfo={piiContext}
            paymentContext={paymentContext}
            bookingContext={bookingContext}
        />
    )

}

export default InvoiceSummaryWrapper;