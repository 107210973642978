import React, { useContext, useEffect } from 'react';
import { CurrencyInput } from 'shared-library';
import { StudyContext } from 'packages/booking-contexts';
import { Column } from './Layout';
import { PaymentContext } from './PaymentContext';

const urlParams = new URLSearchParams(window.location.search);
const isEM124 = urlParams.get('EM-124') === 'true' || new Date() > new Date('2025-03-03');

export const AmountToPay = ({ label, transaction, setTransaction }) => {
  const { selectedLocation } = useContext(StudyContext);
  const { paymentType, total } = useContext(PaymentContext);
  useEffect(() => {
    if (isEM124 && paymentType === 'invoice') {
      setTransaction({ ...transaction, amount: total });
    }
  }, [isEM124, paymentType, total]); 
  const onChange = (values) => {
    if (values.value < parseFloat(total)) {
      setTransaction({ ...transaction, amount: values.value });
    } else {
      setTransaction({ ...transaction, amount: total });
    }
  };

  return (
    <Column>
      <CurrencyInput
        label={label}
        value={isEM124 && paymentType === "invoice"
          ? total
          : transaction.amount === 0
          ? ""
          : transaction.amount < 0
          ? 0
          : transaction.amount}
        suffix={` ${selectedLocation?.currency?.currency}`}
        displayType="input"
        fixedDecimalScale
        decimalScale={2}
        allowNegative={false}
        placeholder="0.00"
        onChange={onChange}
      />
    </Column>
  );
};

AmountToPay.defaultProps = {
  label: 'Amount',
};
