import React, { useCallback, useContext } from 'react';
import { Button, Text } from 'shared-library';
import { Notification } from 'packages/ui-library';
import styled from 'styled-components';
import { getViewUrl } from 'packages/locations';
import { fetchAppsResource } from 'packages/apis';
import { useToasts } from 'react-toast-notifications';
import { StudyContext, PiiContext } from 'packages/booking-contexts';

const InvoiceSectionContainer = styled.div`
  text-align: right;
  margin: 32px 0;
`;

const Row = styled.div`
  button:last-child {
    margin-left: 20px;
  }
`;

const StyledText = styled(Text)`
  margin-top: 10px;
`;

const InvoiceSection = () => {
  const { addToast } = useToasts();
  const { piiContext } = useContext(PiiContext);

  const { studyId, invoiceAvailable } = useContext(StudyContext);
  const invoiceUrl = `${getViewUrl('WEBSITE')}/admin/study/${studyId}/invoice`;
  const handleViewInvoice = useCallback(() => {
    window.open(invoiceUrl, '_blank');
  }, [invoiceUrl]);

  if (!studyId) {
    return null;
  }

  const handleSendInvoice = async () => {
    try {
      await fetchAppsResource(`/study/${studyId}/invoice/email`, 'GET');
      addToast(
        Notification.create(
          'Email sent',
          `Email with invoice has been sent to ${piiContext.email}.`,
        ),
        {
          appearance: 'success',
        },
      );
    } catch (error) {
      addToast(
        Notification.create('Error sending email', `There was an error sending the email.`),
        { appearance: 'error' },
      );
    }
  };

  return (
    <InvoiceSectionContainer>
      <Row>
        <Button
          variant="primary"
          size="medium"
          onClick={handleViewInvoice}
          disabled={!invoiceAvailable}
        >
          View Invoice
        </Button>
        <Button
          variant="primary"
          size="medium"
          onClick={handleSendInvoice}
          disabled={!invoiceAvailable}
        >
          Email Invoice to Member
        </Button>
      </Row>
      {!invoiceAvailable && <StyledText>View or email invoice has been suppressed.</StyledText>}
    </InvoiceSectionContainer>
  );
};

export default InvoiceSection;
