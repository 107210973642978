import React, { useContext, useState } from 'react';
import {
  formatCapitalizedFirstLetter,
  formatCurrency,
  titleCase,
  getMomentFromUnixTimestamp,
} from 'packages/formatters';

import { Button, colors, LoadingSpinner } from 'shared-library';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StudyContext } from 'packages/booking-contexts';
import { fetchAppsResource } from 'packages/apis';
import { PaymentContext } from '../PaymentContext';
import { formatPaymentType } from './TransactionDetail';
import { getPatientInformation } from '../paymentFunctions';
import { Column } from '../Layout';

const transactionShape = PropTypes.shape({
  transactionID: PropTypes.string,
  transactionType: PropTypes.string,
  paymentType: PropTypes.string,
  details: PropTypes.shape({
    paymentMethod: PropTypes.string,
    paymentType: PropTypes.string,
    referralCode: PropTypes.string,
    cardType: PropTypes.string,
    cardLastFour: PropTypes.string,
    referenceID: PropTypes.string,
    status: PropTypes.string,
    invoicePayer: PropTypes.string,
    linkedTransaction: PropTypes.string,
  }),
  memo: PropTypes.string,
  amount: PropTypes.number,
  transactionTime: PropTypes.number,
  status: PropTypes.string,
  refundable: PropTypes.number,
});

const TableBodyRow = styled.tr`
  &:nth-child(odd) {
    background-color: ${colors.white};
  }

  &:nth-child(even) {
    background-color: ${colors.pearl};
  }
`;

const TableCell = styled.td`
  color: ${colors.ash};
  line-height: 30px;
  padding: 20px;
`;

const NoMarginColumn = styled(Column)`
  margin-bottom: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-contents: space-around;
`

const TransactionRow = ({
  openAlert,
  transaction,
  onRefundTransaction,
  onViewTransactionDetail,
}) => {
  const { load, paymentArgChange, refund } = useContext(PaymentContext);
  const {
    transactionID,
    transactionType,
    paymentType,
    details,
    amount,
    transactionTime,
    status,
    refundable,
    currency,
    timezone
  } = transaction;

  const [isLoadingTransaction, setLoadingTransaction] = useState(false);
  const [isLoadingRefund, setLoadingRefund] = useState(false);
  const { studyId } = useContext(StudyContext);

  const formattedPaymentType = formatPaymentType(transaction);
  let formattedAmount = formatCurrency(Math.abs(amount), currency);
  if (amount) {
    if (transactionType.includes('reconciliation')) {
      formattedAmount = formatCurrency(amount, currency);
    } else if (transactionType === 'Refund' || transactionType === 'Remove Discount') {
      formattedAmount = `(${formatCurrency(Math.abs(amount), currency)})`
    }
  }
  const formattedTransactionDate = getMomentFromUnixTimestamp(transactionTime, timezone).format(
    'DD MMM YYYY',
  );
  const formattedDetailsStatus =
    details && details.status && formatCapitalizedFirstLetter(details.status);
  const formattedStatus = status && formatCapitalizedFirstLetter(status);
  const shouldShowRefundCta =
    ['discount', 'payment', 'credit', 'charge', 'reconciliation'].includes(transactionType);
  const getFormattedRefundCta = () => {
    if (transactionType === 'discount' || transactionType === 'credit' || transactionType === 'reconciliation') {
      return 'Remove';
    }
    if (paymentType === 'invoice' && details.status === 'issued') {
      return 'Cancel';
    }
    return 'Refund';
  };

  const removeDiscount = async () => {
    const onSuccess = () => {
      load();
      setLoadingRefund(false);
    };

    const onFail = (e) => {
      openAlert('Discount Code Error', e.data ? e.data : e);
    };
    return refund(transactionID, {}, onSuccess, onFail);
  };

  const showRefundModal = async () => {
    const { parsedBody } = await fetchAppsResource(`/payment/${transactionID}`);
    console.log(parsedBody);
    onRefundTransaction(parsedBody);
    paymentArgChange({ transaction: parsedBody });
  };

  const checkRefundAndProcess = async () => {
    setLoadingRefund(true);
    if (transactionType === 'reconciliation') {
      await showRefundModal();
      setLoadingRefund(false);
      return;
    }
    else if (transactionType === 'Discount' || transactionType === 'credit') {
      if (refundable <= 0) {
        openAlert('Refund Error', 'All funds removed for this payment type.');
        return;
      }
      await removeDiscount();
      setLoadingRefund(false);
      return;
    }
    if (paymentType === 'invoice' && details.status === 'issued') {
      if (refundable <= 0) {
        openAlert('Invoice Cancellation', 'This invoice has already been cancelled');
        return;
      }
      await showRefundModal();
      setLoadingRefund(false);
      return;
    }
    if (refundable <= 0) {
      openAlert('Refund Error', 'All funds refunded for this payment type.');
      return;
    }
    await showRefundModal();
    setLoadingRefund(false);
  };

  const showTransactionModal = async () => {
    setLoadingTransaction(true);
    const stateObj = {};
    try {
      const url = transactionID
        ? `/payment/${transactionID}`
        : `/payment/study/${studyId}/${transactionType}`;
      const { parsedBody: paymentTransaction } = await fetchAppsResource(url);
      const { processedBy } = paymentTransaction;
      stateObj.transaction = paymentTransaction;
      let processedByPii;
      if (processedBy) {
        const { firstname, lastname } = await getPatientInformation(processedBy);
        processedByPii = `${firstname} ${lastname}`;
        stateObj.transaction.processedByPII = processedByPii;
      }
      const getProcessedByName = async (status) => {
        if (paymentTransaction[status]?.processedBy) {
          const statusProcessedBy = paymentTransaction[status].processedBy;
          if (statusProcessedBy === processedBy) {
            stateObj.transaction[status].processedByPII = processedByPii;
          } else {
            const { firstname, lastname } = await getPatientInformation(statusProcessedBy);
            stateObj.transaction[status].processedByPII = `${firstname} ${lastname}`;
          }
        }
      };
      getProcessedByName('issued');
      getProcessedByName('deposit');
      getProcessedByName('paid');
      setLoadingTransaction(false);
      onViewTransactionDetail(stateObj.transaction);
      paymentArgChange(stateObj);
    } catch (err) {
      console.error(err);
      setLoadingTransaction(false);
      openAlert(
        'Error fetching transaction details',
        `Something went wrong fetching transaction ${transactionID}, please try again or file an issue if this persists.`,
      );
    }
  };

  const isRefundDisabled = () => {
    if (transactionType === 'reconciliation') {
      if (amount !== refundable) {
        return true;
      }
      // A $0 reconciliation needs special handling. If there are details and a linked transaction
      // it means that it has a subsequent removal transaction.
      const hasDetails = Object.keys(details).length > 0;
      const hasLinkedTransaction = ![null, undefined].includes(details.linkedTransaction);
      return amount === 0 && hasDetails && hasLinkedTransaction;
    } else if(transactionType !== 'reconciliation' && refundable <= 0 || isLoadingRefund) {
      return true;
    }
    return false;
  }

  return (
    <TableBodyRow key={transactionID}>
      <TableCell>{titleCase(transactionType)}</TableCell>
      <TableCell>{formattedPaymentType}</TableCell>
      <TableCell>{formattedAmount}</TableCell>
      <TableCell>{formattedTransactionDate}</TableCell>
      <TableCell>
        {formattedDetailsStatus}
        {!formattedDetailsStatus && formattedStatus}
      </TableCell>
      <TableCell width="260px">
        <ButtonsContainer>
          {shouldShowRefundCta && (
            <NoMarginColumn width="50%">
              <Button
                onClick={() => checkRefundAndProcess(transaction)}
                size="xsmall"
                variant="primary"
                key={`${transactionID}Refund`}
                id={`${transactionID}r`}
                disabled={isRefundDisabled()}
              >
                {isLoadingRefund ? (
                  <LoadingSpinner color={colors.white} size="small" />
                ) : (
                  getFormattedRefundCta()
                )}
              </Button>
            </NoMarginColumn>
          )}
          <NoMarginColumn width="50%">
            <Button
              disabled={isLoadingTransaction}
              size="xsmall"
              variant="primary"
              key={`${transactionID}Details`}
              onClick={showTransactionModal}
            >
              {isLoadingTransaction ? (
                <LoadingSpinner color={colors.white} size="small" />
              ) : (
                'Details'
              )}
            </Button>
          </NoMarginColumn>
        </ButtonsContainer>
      </TableCell>
    </TableBodyRow>
  );
};

TransactionRow.propTypes = {
  openAlert: PropTypes.func.isRequired,
  transaction: transactionShape.isRequired,
  onRefundTransaction: PropTypes.func.isRequired,
  onViewTransactionDetail: PropTypes.func.isRequired,
};

export default TransactionRow;
