import React from 'react';
import ReactSelect, { components } from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../colors';
import Icon from '../Icon';
import Label from '../Label';
import {AshLabel} from "../Label/Label";

const SelectInputLayout = styled.div`
  width: ${({ width }) => width || '100%'};
`;

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon type="down-caret" foregroundColor={colors.primary} />
  </components.DropdownIndicator>
);

const StyledReactSelect = styled(ReactSelect).attrs({
  classNamePrefix: 'react-select',
  components: { DropdownIndicator },
})`
  .react-select {
    &__control {
      border: 1px solid ${colors.smoke};
      border-radius: 3px;
      min-height: auto;

      &:hover {
        border-color: ${colors.smoke};
        box-shadow: 0 2px 4px 0 rgba(217, 217, 217, 0.5);
      }

      &--is-focused,
      &--is-focused:hover {
        border-color: ${colors.primary};
        box-shadow: none;
        outline: 0;

        .react-select__single-value {
          color: ${colors.charcoal};
        }
      }

      &--is-disabled {
        background-color: ${colors.pearl};
        border-color: ${colors.cloud};
        box-shadow: none;

        .react-select__single-value {
          color: ${colors.smoke};
        }
      }

      &--is-disabled svg path {
        fill: ${colors.smoke};
      }
    }

    &__value-container {
      padding: 4px 8px;
    }

    &__placeholder,
    &__single-value,
    &__option {
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-weight: 600;
    }

    &__placeholder {
      color: ${colors.smoke};
    }

    &__single-value {
      color: ${colors.ash};
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding: 7px 8px;
    }

    &__menu {
      border-radius: 0 0 3px 3px;
      margin-top: 0;
      overflow: hidden;
      transform: translateZ(0);
      z-index: 2;
    }

    &__menu-list {
      padding: 0;
    }

    &__option {
      padding: 16px 10px;
      background-color: ${colors.white};
      text-align: left;

      &--is-focused {
        background-color: ${colors.pearl};
      }

      &:active {
        background-color: ${colors.cloud};
      }

      &--is-selected,
      &--is-selected:active {
        background-color: ${colors.primary};
      }
    }
  }
`;

const SelectInput = ({ innerRef, className, disabled, width, label, onChange, ash, ...inputProps }) => (
  <SelectInputLayout className={className} width={width} >
    {label && !ash && <Label htmlFor={inputProps.id}>{label}</Label>}
    {label && ash && <AshLabel htmlFor={inputProps.id}>{label}</AshLabel>}
    <StyledReactSelect ref={innerRef} isDisabled={disabled} onChange={onChange} {...inputProps} />
  </SelectInputLayout>
);

SelectInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string,
};

SelectInput.defaultProps = {
  className: null,
  disabled: false,
  label: null,
  onChange: null,
  width: null,
};

export default SelectInput;
